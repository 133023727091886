@import "src/assets/extend";

.inner-field {
  display: flex;
  flex-direction: column;

  &__label {
    @extend %label;

    &_required {
      @extend %label_required;
    }

    &_dense {
      @extend %label_dense;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    align-items: flex-end;
    box-shadow: 0 0 0 1px var(--color30);
    border-radius: 4px;
    transition: box-shadow 0.1s ease-in-out;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    overflow: hidden;

    &_error {
      box-shadow: 0 0 0 1px var(--red30);
    }

    &_rtl {
      flex-direction: row-reverse;
    }

    &_disabled {
      background-color: var(--grey0);
      // bug: https://alarislabs.atlassian.net/browse/MK-3909
      // box-shadow: 0 0 0 1px var(--color10) !important;
    }

    &:hover {
      box-shadow: 0 0 0 1px var(--color80);
    }

    &:focus-within {
      box-shadow: 0 0 0 2px var(--color80);
    }
  }
}
