@import "../../../../assets/extend";

.service-enabled-form {
	@extend %form;

	&__content {
		@extend %form__content;

		padding-bottom: 15px;
	}

	&__row {
		@extend %form__row;

		margin-top: 24px;
	}
}
