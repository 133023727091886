.config-cell-renderer {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &_confirmed {
    background: #ecf7ed;
  }
  &_not-confirmed {
    background: #f7f7fa;
  }
}
