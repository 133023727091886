.custom-view {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__search {
    box-shadow: none !important;
    margin-bottom: 24px;
    padding: 0 2px;

    input {
      color: var(--grey40) !important;
      font-family: var(--inter) !important;
      font-size: 16px !important;
      line-height: 150% !important;
      letter-spacing: 0.0275em !important;
    }

    svg {
      fill: var(--grey30) !important;
    }
  }

  &__check-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-bottom: 24px;
    max-height: calc(100% - 175px);

    &__item {
      background-color: var(--grey0);
      border-radius: 4px;
      margin: 4px 0 !important;
      font-weight: normal;
    }
  }

  &__apply {
    display: flex;
    max-width: 64px;
    margin-left: auto;

    &__text {
      color: var(--blue0);
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.018em;
    }
  }
}
