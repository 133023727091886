.row {
  display: flex;
}

.key {
  width: 100%;
  font-size: 14px;
  height: 1.75rem;
  padding: 8px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: font-weight 0.2s, color 0.2s;

  &_missmatchValue {
    color: var(--orange60);
  }
  &_resolved {
    color: var(--green60);
    font-weight: bold;
  }
}

.value {
  width: 100%;
  &_changed > input {
    font-weight: bold;
  }

  > input {
    width: 100%;
    border-bottom: none;
    transition: font-weight 0.2s;
  }
}

.input {
  border: none;
  width: 100%;
  height: 1.75rem;
  padding-right: 6px;
  font-size: 14px;
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
  background-color: transparent;
  color: var(--color100);
  border-bottom: 1px solid var(--grey30);
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;

  &:not(:disabled):not(:focus):hover {
    border-bottom-color: var(--grey40);
  }
  &:focus {
    border-bottom-color: var(--grey80);
  }

  &:disabled {
    opacity: 0.8;
    border-bottom-color: transparent;
    pointer-events: none;
  }

  &:disabled:-webkit-autofill {
    background-color: transparent;
  }
}
