@import "../../../../../../assets/extend";

.note-menu {
	&__overlay {
		@extend %popover-overlay;

		padding: 8px;
		gap: 2px;
	}

	&__item {
		display: flex;
		color: var(--grey100);
		letter-spacing: 0.018em;
		font-size: 14px;
		line-height: 143%;
		padding: 4px 8px;
		transition: background-color 0.2s;
		white-space: nowrap;
		cursor: pointer;

		&:hover {
			background-color: var(--grey5);
		}
	}

	&__item-spinner {
		margin-right: 6px;
	}
}
