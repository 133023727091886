.countryChart {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.list {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 5px;
  width: 35%;
}

.item {
  font-size: 16px;
  color: var(--grey50);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
  min-height: 20px;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;

  &:first-child {
    margin-top: 30px;
  }
}
