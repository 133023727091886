@import 'src/assets/extend';

.radio-button-field {
	display: flex;
	flex-direction: column;

	&__label {
		@extend %label;

		&_required {
			@extend %label_required;
		}
	}

	&__options > div:not(:first-of-type) {
		margin-top: 8px;
	}
}
