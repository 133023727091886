.row {
	display: contents;

	> div {
		grid-column: auto;
		padding: 2px 6px;
		display: flex;
		background-color: #fff;
		transition: background-color 0.2s ease-in-out;
		border-left: 1px solid var(--grey0);

		&:first-of-type {
			padding: 0;
			position: sticky;
			left: 0;
			z-index: 1499;
		}
		&:last-of-type {
			position: sticky;
			right: 0;
			z-index: 1;
		}

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4) {
			border-left-color: transparent;
		}
	}

	&_active,
	&_selected,
	&:hover {
		> div:first-of-type {
			background-color: var(--blue50);
		}

		.buttonsWrapper svg {
			opacity: 1;
		}
	}

	&:not(&_active):hover > div:first-of-type {
		background-color: var(--blue30);
	}

	&_selected:not(&_active) {
		div[class*='Multiselect_value'],
		input {
			font-weight: 600;
		}
	}

	&_withActiveSearch > div:first-of-type {
		background-color: var(--orange50);
	}

	&_readOnly > div:last-child {
		right: unset;
		position: static;
	}
}

.iconButton {
	cursor: pointer;
	fill: var(--grey30);
	transition: all 0.2s ease-in-out;
	width: 18px;
	height: 18px;

	&_disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&:not(&_disabled):hover {
		fill: var(--color50);
	}
}

.chevron {
	cursor: pointer;
	transform: scale(-1, 1);
	transition: transform 0.2s ease-in-out;

	&:hover > svg {
		fill: var(--grey50) !important;
	}

	> svg {
		fill: var(--grey20) !important;
		transition: fill 0.2s ease-in-out;
	}

	&_reversed {
		transform: scale(1, -1);

		> svg {
			fill: var(--blue50) !important;
		}
	}
}

.buttonsWrapper {
	display: flex;
	align-self: baseline;
	gap: 12px;
	transition: opacity 0.2s ease-in-out;
	padding-top: 6px !important;
	height: 100%;

	svg {
		opacity: 0;
	}

	> div {
		align-self: baseline;
	}

	&_alwaysVisible svg {
		opacity: 1;
	}
	&_alwaysHidden svg {
		opacity: 0 !important;
	}
}

.chevronCell {
	display: flex;
	flex: unset;

	> span {
		align-self: baseline;
		margin-top: 6px;
	}
}

.rowError {
	fill: var(--color50);
	min-width: 15px;
	margin-top: 4px;
}

.childrenWrapper {
	display: none;

	&_expand {
		display: contents;
	}
}
