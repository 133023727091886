@import "src/assets/extend";

.exchange-rate-field {
  display: flex;
  flex-direction: column;
  width: 220px;

  &__label {
    @extend %label;
  }

  &__row {
    display: flex;
    width: 100%;
    align-items: flex-end;

    &__unit {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      margin-right: 8px;
      color: var(--grey100);
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }

  &__currency {
    background-color: var(--grey0);
    margin-bottom: 1px;
    margin-left: -1px;
    border-radius: 0 0.25rem 0.25rem 0;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    border: 1px solid var(--grey30);
    border-left: 0;
    cursor: not-allowed;
  }
}
