.priceCell {
	position: relative;
	flex-direction: column;
	gap: 4px;
}

.input {
	border: none;
	height: 1.75rem;
	padding-right: 6px;
	width: 101px;
	font-size: 14px;
	letter-spacing: var(--letter_spacing_body);
	font-weight: var(--font_weight_body);
	background-color: transparent;
	color: var(--color100);
	border-bottom: 1px solid var(--grey30);
	transition: all 0.2s ease-in-out;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: right;
	font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;

	&:not(&_error):not(:disabled):not(:focus):hover {
		border-bottom-color: var(--grey40);
	}
	&:not(&_error):focus {
		border-bottom-color: var(--grey80);
	}

	&:disabled {
		opacity: 0.8;
		border-bottom-color: transparent;
		pointer-events: none;
	}

	&:not(:disabled) {
		background-color: var(--grey0);
	}

	&_error {
		position: relative;
		border-bottom-color: var(--red50);

		&::placeholder {
			color: var(--red50);
		}
	}

	&:disabled:-webkit-autofill {
		background-color: transparent;
	}
}

.error {
	color: var(--red50);
	position: absolute;
	z-index: 1;
	font-size: 10px;
	letter-spacing: var(--letter_spacing_caption);
	font-weight: var(--font_weight_caption);
	animation: shake-horizontal 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	top: calc(100% - 2px);
}

.col {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
