.notes-block {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: var(--grey0);
	padding: 14px;
	border-radius: 4px;
	position: absolute;
	inset: 0;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		letter-spacing: 0.018em;
		color: var(--grey50);
		font-weight: 400;
		font-size: 14px;
		line-height: 143%;
	}

	&__overlay {
		display: flex;
		flex-direction: column;
		background-color: var(--white);
		box-shadow: var(--shadow16dp);
		padding: 24px;
		border-radius: 8px;
		width: 509px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		margin-top: 14px;
		overflow: auto;
	}

	&__list-item {
		display: flex;

		&:not(:first-of-type) {
			margin-top: 16px;
			padding-top: 16px;
			border-top: 1px solid var(--grey10);
		}
	}

	&__list-item-column {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-right: 4px;
		font-size: 12px;
		line-height: 126%;
		letter-spacing: 0.033em;
		font-feature-settings: 'ss01' on;
	}

	&__list-item-row {
		display: flex;
		font-weight: 400;
		font-size: 10px;
		line-height: 126%;
		letter-spacing: 0.033em;
		font-feature-settings: 'ss01' on;
		color: var(--grey100);
		margin-top: 4px;
	}

	&__label {
		color: var(--grey30);
		margin-right: 3px;
	}

	&__buttons {
		display: flex;
		margin-top: 14px;
		justify-content: flex-end;
		height: 28px;
		gap: 16px;
	}

	.bp3-input-group {
		margin-top: auto;
		padding-top: 8px;
	}
}
