.container {
  position: relative;
}
.modal {
  width: 1154px;
  min-width: 1154px;
  max-width: 1154px;
}
.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}
.columnsButton {
  position: absolute;
  right: 0;
  top: 0;
}
.massControls {
  display: flex;
  height: 42px;
  margin-top: 16px;
  justify-content: space-between;
}
.regularText {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  letter-spacing: 0.022em;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
  color: var(--grey100);
}
.primaryText {
  color: var(--blue60);
}
.massControlsLeft {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
}
.massControlsRight {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 14px;
}
