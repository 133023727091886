.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.body {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 26px 24px;
  gap: 32px;
  overflow-y: scroll;
}

.closeButton {
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  height: 68px;
  min-height: 68px;
  border-bottom: 1px solid var(--grey5);
}

.headerItems {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.headerItem {
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  color: var(--gray100);
  font-size: 14px;
  font-family: var(--inter);
  font-style: normal;
  font-weight: 500;
  line-height: 143%;
  letter-spacing: 0.252px;
  justify-content: center;
  border-right: 1px solid var(--grey5);
}

.headerChannelItem {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.headerItemTitle {
  color: var(--gray40);
  font-size: 14px;
  font-family: var(--inter);
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.252px;
}

.controls {
  display: flex;
  flex-direction: row;
  padding: 16px 22px 24px 22px;
  border-top: 1px solid var(--grey5);
  justify-content: flex-end;
  gap: 14px;
  height: 78px;
  min-height: 78px;
}
