@import '../../../../assets/extend';

.periodic-fee {
	display: flex;
	flex: 1;

	&__shadow {
		@extend %form__top-shadow;
	}

	&__history-list {
		display: flex;
		flex-direction: column;
		width: 270px;
		height: 100%;
		border-right: 1px solid var(--grey5);
	}

	&__content {
		display: flex;
		flex-direction: column;
		padding: 24px;
		flex: 1;
	}

	&__form {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__row {
		display: flex;
	}

	&__col {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__divider {
		height: 1px;
		width: 100%;
		background-color: var(--grey5);
		margin: 24px 0;
	}

	&__subtitle {
		font-weight: 500;
		font-size: 20px;
		line-height: 158%;
		letter-spacing: 0.004em;
		color: var(--grey100);
		margin-bottom: 14px;
	}
}
