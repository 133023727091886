$screen-M: 1366px;

// fonts
$primary-font-regular: 'notosans-regular';
$primary-font-bold: 'notosans-bold';

// colors
$color-vk:#4c75a3;
$color-item-hover: #EBF2FF;
$color-rsc:#5a5b6a;
$color-viber:#8e24aa;
$color-grey: rgba(255, 255, 255, 0.6);
$color-dark-grey-opacity: rgba(0, 0, 0, 0.4);
$color-white-opacity: rgba(#fff, 0.3);
$color-white-grey:#F7F8FC;
$color-dark-grey: #1a1d25;
$color-light-blue:#2979FF;
$color-attention: #ff4040;
$color-green: #38a213;
$color-orange: #eaa726;
$color-black: #000;
$color-white: #fff;
$primary: #222430;
$secondary-color: #2b313e;
$accent-color: #0c91c0;

// styles
$border-style: 1px solid $color-grey;
$box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1);
// get inline styles
@function border-style($color) {
  @return 1px solid $color;
}
@function box-shadow($color) {
  @return 4px 0 4px $color;
}




