@import "../../../../assets/extend";

.container {
  @extend %form;
}

.content {
  @extend %form__content;

  padding-bottom: 15px;
  gap: 24px;
}
