@import "../../../../../assets/extend";

.target {
  color: var(--grey40);
  font-style: italic;
  line-height: 150%;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    right: 6px;
    top: 10px;
    background: url("../../ServiceTab/TreeTable/icons/chevron-down.svg") 100% no-repeat;
    transition: transform 0.2s linear, opacity 0.2s ease-in-out;
  }

  > span[class*="bp3-icon"] {
    margin-right: 4px;
    margin-bottom: 2px;
  }

  &:not(&_disabled):hover {
    color: var(--grey60);

    &::after {
      opacity: 1;
    }
  }

  &_active::after {
    transform: scale(1, -1);
  }

  &_disabled {
    cursor: not-allowed;
  }

  &_setted {
    color: var(--grey100);
    font-style: normal;
  }
}

.overlay {
  @extend %popover-overlay;

  width: 400px;
  padding: 14px;
  font-size: 14px;
  margin-top: 4px;
  gap: 24px;
}

.overlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closePopoverButton {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  color: var(--grey50);

  &:hover {
    color: var(--color70);
  }
}

.buttonsWrapper {
  display: flex;
  gap: 14px;
  margin-top: auto;
}

.row {
  display: flex;
  color: var(--grey40);
  font-size: 14px;
  gap: 4px;
}

.cogButton {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  color: var(--grey50);
  margin-left: auto;

  &:hover {
    color: var(--blue50);
  }
}

.modal {
  width: 600px;
  height: 400px;
  padding: 24px;
}

.modalContent {
  flex-direction: column;
  overflow: hidden;
}

.mappingCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid var(--grey10);
  overflow: hidden;
  border-radius: 4px;
}

.cardHeader {
  display: flex;

  > div {
    width: 100%;
    background-color: var(--grey10);
    padding: 4px 8px;
    position: sticky;
    top: 0;
    z-index: 2;
    overflow: hidden;
  }
}

.cardSearch {
  display: flex;

  > div {
    display: flex;
    width: 100%;
    align-items: center;
    position: sticky;
    top: 24px;
    z-index: 2;
    background-color: #fff;
    padding: 4px 8px;
    border-bottom: 1px solid var(--grey10);

    &:focus-within span {
      color: var(--grey70);
    }

    span {
      color: var(--grey40);
      margin-right: 4px;
      transition: color 0.2s;
    }

    input {
      border: none;
      font-size: 14px;
    }
  }
}

.cardList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.cardListItem {
  display: flex;

  > div {
    &:first-of-type {
      width: 100%;
      font-size: 14px;
      height: 1.75rem;
      padding: 8px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:last-of-type {
      width: 100%;
      //background-color: transparent !important;

      > input {
        width: 100%;
        border-bottom: none;
      }
    }
  }
}
