.header-right-tools {
	display: flex;
	align-items: center;
	padding-left: 14px;

	&__name {
		font-size: 14px;
		font-weight: 500;
		line-height: 143%;
		margin-left: 12px;
		color: var(--grey80);
		letter-spacing: 0.018em;
		white-space: pre;
	}

	&__logout{
		background: var(--color0);
		border-radius: 100px;
		border: none;
		width: 28px;
		height: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 24px;
		cursor: pointer;
	}
}
