.container {
  display: flex;
  position: relative;
  margin-top: 24px;
}

.cardsList {
  display: flex;
  height: 184px;
  gap: 40px;
  overflow-x: auto;
  position: relative;
}

.slideButtonLeft,
.slideButtonRight {
  z-index: 1;
  position: absolute;
  top: calc(50% - 20px);
}

.slideButtonLeft {
  left: 8px;
}

.slideButtonRight {
  right: 8px;
}
