.fieldArray {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin: 24px 0;
}

.row {
  display: flex;
  gap: 24px;
}

.iconButton {
  cursor: pointer;
  color: var(--grey30);
  transition: color 0.2s;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--grey70);
  }
}
