.status-cell-renderer {
  text-transform: uppercase !important;

  &_confirmed {
    background-color: var(--green5) !important;
    color: var(--green70) !important;
  }

  &_not-confirmed {
    background-color: var(--red5) !important;
    color: var(--red70) !important;
  }
}
