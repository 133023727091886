.invoices-layout {
	display: flex;
	flex-direction: column;
	position: relative;

	&__table-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		flex: 1;
	}

	&__row {
		display: none;
		align-items: center;
		font-size: 14px;
		line-height: 143%;
		color: #19191D;
		letter-spacing: 0.018em;
		padding: 8px;
		background-color: var(--white);
		border-top: 1px solid #9696A0;
	}

	&__totals {
		white-space: nowrap;
		margin-left: auto;
		margin-right: 15%;
	}

	&__toggle {
		cursor: pointer;
		color: var(--grey70);
		white-space: nowrap;
		padding-left: 30px;
	}

	&__pagination {
		display: flex;
		padding-top: 9px;
		border-top: 1px solid #9696A0;
		width: 100%;
		justify-content: flex-end;
	}

	&__columns-button {
		position: absolute;
		right: 0;
		top: 0;
	}

	&__tabs {
		display: flex;
		flex: 7;
		background-color: var(--white);
		border-top: 1px solid #9696A0;

		.bp3-tab-list {
			padding: 17px 14px;
		}
		.bp3-tab-panel {
			height: 100%;
		}
	}

	&__tab {
		height: 100%;
	}

	&:has(.invoices-layout__columns-button) {
		.MuiTabs-root {
			padding-right: 88px;
		}
	}
}
