.select-channel {
	display: flex;
	flex-direction: column;

	&__radio {
		width: 100%;
	}

	> div {
		margin-bottom: 4px;
		width: 100%;
	}
}