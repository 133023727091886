.popover[class*='minimal'] {
  box-shadow: none;
  margin-top: 4px !important;
  background: transparent;
}
.popover span {
  width: 100%;
}
.popover > div[class*='popover-content'] {
  background: transparent;
}
/*CARET-DOWN*/
.outlined::after,
.raised::after,
.smooth::after {
  color: var(--color40);
}
.outlined:hover::after,
.raised:hover::after,
.smooth:hover::after {
  color: var(--color60);
}
.outlined:active::after,
.outlined:focus::after,
.raised:active::after,
.raised:focus::after,
.smooth:active::after,
.smooth:focus::after {
  color: var(--color90);
}
.filled::after {
  color: var(--color10);
}
.filled:hover::after {
  color: var(--color5);
}
.filled:active::after,
.filled:focus::after {
  color: var(--color0);
}
/**/

/*OUTLINED DROPDOWN*/
.outlined {
  margin: 2px;
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 1px var(--color30);
}
.outlined:hover {
  box-shadow: 0 0 0 1px var(--color80);
}
.outlined:active,
.outlined:focus {
  box-shadow: 0 0 0 2px var(--color80);
}

/* RAISED DROPDOWN*/
.raised {
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow2dp);
}
.raised:hover {
  box-shadow: var(--shadow4dp);
}
.raised:active,
.raised:focus {
  box-shadow: var(--shadow8dp);
}

/* SMOOTH DROPDOWN*/
.smooth {
  border: none;
  color: var(--color100);
  background: var(--color5);
}
.smooth:hover {
  background: var(--color10);
}
.smooth:active,
.smooth:focus {
  background: var(--color20);
}

/* FILLED DROPDOWN*/
.filled {
  border: none;
  color: var(--color0);
  background: var(--color40);
}
.filled:hover {
  background: var(--color50);
}
.filled:active,
.filled:focus {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
}
