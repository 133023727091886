.titleIcon {
  height: 22px;
  width: 22px;

  path {
    fill: var(--grey30);
  }
}

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 14px;
  border-bottom: 2px solid var(--grey10);
}

.messageContainerCarousel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey10);
  }
}

.messageTile {
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
  color: var(--grey50);
  font-size: 14px;
  font-family: var(--inter);
  line-height: 143%;
  letter-spacing: 0.252px;
}

.messageHeader {
  color: var(--grey100);
  font-size: 20px;
  font-family: var(--inter);
  font-weight: 500;
  line-height: 158%;
  letter-spacing: 0.08px;
}

.messageBody {
  color: var(--grey100);
  font-size: 18px;
  font-family: var(--inter);
  line-height: 158%;
  letter-spacing: 0.08px;
  margin-top: 8px;
}

.messageFooter {
  color: var(--grey100);
  font-size: 16px;
  font-family: var(--inter);
  line-height: 158%;
  letter-spacing: 0.44px;
  margin-top: 4px;
}

.messageList {
  margin-top: 4px;
}

.messageListSection {
  margin-bottom: 4px;
}

.messageListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.messageListItemDescription {
  color: var(--grey50);
}

.messageButtons {
  display: flex;
  gap: 14.5px;
  margin-top: 8px;
  flex-wrap: wrap;
}

.messageButtonsOutside {
  display: flex;
  gap: 14.5px;
  margin-top: 8px;
  flex-wrap: wrap;
}

.messageButtonsOutside {
  display: flex;
  gap: 14.5px;
  margin-top: 14px;
  flex-wrap: wrap;
}

.messageButtonsBordered {
  display: flex;
  gap: 14.5px;
  margin-top: 8px;
  flex-wrap: wrap;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--grey5);
}

.messageCarouselButtons {
  display: flex;
  gap: 14.5px;
  flex-wrap: wrap;
}

.messageButtonsItem {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: var(--inter);
  line-height: 150%;
  letter-spacing: 0.44px;
  padding: 10px 13px;
  border-radius: 40px;
  color: var(--grey50);
  border: 1px solid var(--grey10);
  gap: 4px;
}

.bodyContainer {
  overflow-wrap: break-word;
  padding: 0 20px;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  s {
    text-decoration: line-through;
  }

  kbd {
    font-family: monospace;
  }
}

.highlight {
  color: var(--blue70);
}

.bodyCarouselContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 14px;
}

.locationProp {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.locationPropName {
  color: var(--grey60);
}

.locationPropValue {
  color: var(--grey100);
}
