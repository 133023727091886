.container {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 11px 24px;
  font-size: 14px;
  line-height: 150%;
  white-space: nowrap;
  color: var(--grey100);
  cursor: pointer;

  svg {
    color: var(--grey40);
    fill: currentColor;
  }

  &:hover {
    color: var(--grey100);
  }

  &_isHovered,
  &:hover:not(&_active) {
    background-color: var(--grey5);

    svg {
      color: var(--grey70);
    }
  }

  &_active {
    background-color: var(--blue5);

    svg {
      color: var(--blue60);
    }
  }
}

.rightIcon {
  margin-left: auto;
}
