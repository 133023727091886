.history-item {
	display: flex;
	flex-direction: column;
	padding: 10px 12px;
	border-bottom: 1px solid var(--grey5);
	background-color: var(--grey0);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&_active {
		border-right: 2px solid var(--blue70);
		background-color: unset;
	}

	&__range {
		display: flex;
		margin-top: 8px;
	}
}
