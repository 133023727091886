.container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  flex: 7;
  padding-right: 40px;
  margin-right: 40px;
  border-right: 1px solid var(--grey10);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  > span {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.001px;
  }
}

.content {
  display: flex;
  flex: 1;
  margin-top: 16px;
}

.previewWrapper {
  display: flex;
  min-width: 370px;
  width: 370px;
  padding-top: 9px;
}

.previewPlaceholder {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: var(--grey50);
}
