@import "../../../../assets/extend";

.form {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 24px;
}

.fieldsContainer {
  flex: 1;
}

.fields {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 0;
  gap: 16px;
}

.history {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 24px 0 24px 24px;
  max-height: 408px;
  overflow: auto;
  gap: 5px;
}

.divider {
  width: 1px;
  background-color: var(--grey5);
  margin-bottom: 88px;
  margin-top: 24px;
}

.version {
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: var(--blue0);

    .button {
      display: flex;
    }
  }

  &_selected {
    background-color: var(--blue5);

    &:hover {
      background-color: var(--blue10);
    }
  }
}

.button {
  display: none;
}

.left {
  flex: 4;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.center {
  flex: 2;
  font-size: 15px;
}

.right {
  height: 36px;
  width: 36px;
}

.company {
  font-size: 15px;
  font-weight: bold;
}

.dates {
  font-size: 13px;
  color: var(--grey30);
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.schedule {
  background-color: var(--blue60);
}

.active {
  background-color: var(--green60);
}

.previous {
  background-color: var(--grey30);
}

.spinner {
  height: 300px;
}

.shadow {
  @extend %form__top-shadow;
}

.content {
  @extend %form__content;
  padding-bottom: 12px;
}

.callout {
  margin-bottom: 16px;
}

.noHistory {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--grey5);
  margin: 0 12px 12px 12px;
}

.noHistoryHeader {
  font-weight: bold;
  font-size: 20px;
}

.noHistoryBody {
  font-size: 15px;
  color: var(--grey30);
}
