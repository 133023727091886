.run-report-form {
  display: flex;
  flex: 3;
  flex-direction: column;

  &__description-callout {
    margin-top: 16px;
    margin-bottom: 5px;
    width: 443px;
    background-color: var(--grey0),
  }

  &__description-callout-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 126%;
    letter-spacing: 0.02em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
    color: var(--grey60);
  }

  &__block {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: var(--white);
    border-radius: 8px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__description-title {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid var(--grey0);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.022em;
    font-feature-settings: 'ss01' on;
  }

  &__description-icon {
    margin-right: 8px;
  }

  &__description-body {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 126%;
    letter-spacing: 0.033em;
    font-feature-settings: 'ss01' on;
    color: var(--grey60);
    padding: 23px 0 0 17px;
  }

  &__description-body-title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 126%;
    align-items: center;
    letter-spacing: 0.168em;
    text-transform: uppercase;
    display: flex;
    color: var(--grey40);
    margin-bottom: 8px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex: 2;
    border-right: 1px solid var(--grey0);
  }

  &__body {
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: column;
    height: 0;
    flex: 1 1 auto;
    overflow-y: scroll;
  }

  &__title {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 158%;
    letter-spacing: 0.004em;
    padding: 18px 0 0 16px;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    height: 56px;
    margin-bottom: 1px;
  }

  &__button {
    &_run {
      margin-left: 24px;
      align-self: auto;
    }
    &_edit {
      background-color: var(--white)!important;
      align-self: auto;
    }
    &_delete {
      margin-right: 24px;
    }
  }

  &__button-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    color: var(--green0);
    letter-spacing: 0.022em;
    text-transform: none;

    &_disabled {
      color: var(--green40);
    }

    &_outlined{
      color: var(--blue70);
    }
  }
}
