.form {
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	flex: 1;
	position: relative;
}

.content {
	display: flex;
	flex-direction: column;
	padding: 24px;
	overflow-y: auto;
}

.titleBold {
	letter-spacing: 0.0075em;
	font-weight: 700;
	font-size: 20px;
	line-height: 150%;
	color: var(--grey100);
}

.title {
	letter-spacing: 0.004em;
	font-weight: 500;
	font-size: 20px;
	line-height: 158%;
	color: var(--grey100);
	margin-top: 24px;
}

.hint {
	letter-spacing: 0.033em;
	font-feature-settings: 'ss01' on;
	font-size: 12px;
	line-height: 126%;
	color: var(--grey50);
}

.row {
	display: flex;
	gap: 20px;
	margin-top: 16px;
}

.apiKey {
	position: absolute;
	top: 10px;
	right: 8px;
	cursor: pointer;
}

.callout {
	margin-top: 24px;
	background-color: inherit !important;
	color: inherit !important;
	> h4 {
		color: inherit !important;
	}
}

.button {
	margin-top: 16px;
	align-self: baseline;
	min-height: 40px;

	> span[icon="send-message"] {
		transform: rotate(-45deg) translateX(3px);
	}
}

.footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 24px;
	width: 100%;
	padding: 16px 24px;
	> button {
		align-self: initial;
	}
}
