.invoice-details {
	&__column {
		display: flex;
		flex-direction: column;
		width: 100%;

		> div:not(:first-of-type) {
			margin-top: 14px;
		}
	}

	&__buttons {
		display: flex;
		margin-left: auto;
		gap: 8px;
	}

	&__notes-wrapper {
		display: flex;
		width: 371px;
		height: 100%;
		position: relative;
	}
}
