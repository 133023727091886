.text-cell-renderer {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	&__variable {
		color: var(--blue70);
	}
}
