.charge-details {
	&__column {
		display: flex;
		flex-direction: column;
		width: 100%;

		> div:not(:first-of-type) {
			margin-top: 14px;
		}
	}
}
