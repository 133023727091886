@import "../../../../../../../assets/extend";

.inputWrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	align-self: baseline;
	transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;

	&_withDependsIcon {
		.input {
			padding-left: 44px;
			color: var(--grey50);
		}

		&::before {
			content: 'by';
			text-align: right;
			font-size: 14px;
			color: var(--grey30);
			width: 36px;
			height: 14px;
			position: absolute;
			left: 2px;
			bottom: 8px;
			background-image: url("../../icons/list-tree.svg");
			background-size: 14px;
			background-repeat: no-repeat;
		}
	}

	&::after {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		right: 6px;
		background: url("../../icons/chevron-down.svg") 100% no-repeat;
		transition: transform 0.2s linear, opacity 0.2s ease-in-out;
	}

	&:not(&_disabled):hover::after {
		opacity: 1;
	}

	&_active::after {
		transform: scale(1, -1);
	}

	&_disabled {
		cursor: not-allowed;
		opacity: 0.8;

		&::after {
			opacity: 0;
		}

		.input {
			border-bottom-color: transparent !important;
		}
	}

	&:not(&_disabled) {
		background-color: var(--grey0);
	}
}

.inputIcon {
	width: 14px;
	height: 14px;
	position: absolute;
	left: 7px;
	bottom: 8px;
}

.input {
	border: none;
	padding-left: 6px;
	padding-right: 20px;
	height: 1.75rem;
	font-size: 14px;
	letter-spacing: var(--letter_spacing_body);
	font-weight: var(--font_weight_body);
	background-color: transparent;
	color: var(--color100);
	vertical-align: middle;
	border-bottom: 1px solid var(--grey30);
	transition: border-bottom-color 0.2s ease-in-out;
	cursor: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&_withIcon {
		padding-left: 33px;
	}

	&_active {
		border-bottom-color: var(--grey80);
		cursor: text;
	}

	&:not(&_error):not(&_active):hover {
		border-bottom-color: var(--grey40);
	}

	&_error {
		border-bottom-color: var(--red50);

		&::placeholder {
			color: var(--red50);
		}
	}
}

.spinner {
	position: absolute;
	right: 4px;
}

.error {
	color: var(--red50);
	position: absolute;
	z-index: 1;
	font-size: 10px;
	letter-spacing: var(--letter_spacing_caption);
	font-weight: var(--font_weight_caption);
	animation: shake-horizontal 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	top: 100%;
}

.caret {
	position: absolute;
	right: 0;
	top: 50%;
	transform: scale(-1, 1) translateY(-50%);
	cursor: pointer;
	transition: transform 0.2s ease-in-out;

	path {
		transition: fill 0.2s ease-in-out;
	}

	&_open {
		transform: scale(1, -1) translateY(50%);

		path {
			fill: #4A4B57;
		}
	}
}

.overlay {
	@extend %field-overlay;
	position: unset;

	max-height: 30px * 10;
	padding: 0;
}

.overlayItem {
	@extend %field-overlay__item;

	min-height: 1.75rem;
	display: block;
	padding: 8px 6px;
	font-size: 14px;
	white-space: nowrap;
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	border-radius: 0;

	&_disabled {
		cursor: not-allowed;
		opacity: 0.7;

		&:hover {
			background-color: initial;
		}
	}

	&_withIcon {
		padding-left: 33px;
	}
	&_hoveredByChild {
		background-color: #EBF2FF;
	}
}

.itemIcon {
	width: 14px;
	height: 14px;
	position: absolute;
	left: 7px;
	bottom: 8px;
}

.portalContent {
	z-index: 99999;
}
