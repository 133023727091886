@import "src/assets/extend";

.checkbox-field {
  display: flex;
  flex-direction: column;

  &__label {
    @extend %label;

    &_required {
      @extend %label_required;
    }
  }
}
