.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;

  &_readOnly {}
}

.list {
  height: 100%;
  width: 100%;

  div[class="ReactVirtualized__Grid ReactVirtualized__List"] {
    overflow: auto !important;
  }
  div[class="ReactVirtualized__Grid__innerScrollContainer"] {
    overflow: unset !important;
  }
}

.header {
  display: grid;
  grid-auto-rows: 30px;
  overflow: hidden;

  &_readOnly > div:last-child {
    right: unset;
    position: static;
  }
}

.header > div {
  grid-column: auto;
  padding: 8px;
  white-space: nowrap;
  background-color: #f2f2f2;
  color: var(--grey50);
  position: sticky;
  top: 0;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid var(--white);

  &:first-child {
    z-index: 2;
  }
  &:last-child {
    right: 0;
    z-index: 2;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    border-left-color: #f2f2f2;
  }

  &:nth-child(4) {
    padding-left: 30px;
  }
}

.item {
  display: grid;
  grid-auto-rows: 1fr;

  > div {
    grid-column: auto;
    padding: 2px 6px;
    display: flex;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
    border-left: 1px solid var(--grey0);
    white-space: nowrap;

    &:first-of-type {
      padding: 0;
      position: sticky;
      left: 0;
      z-index: 1499;
    }
    &:last-of-type {
      position: sticky;
      right: 0;
      z-index: 1;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      border-left-color: transparent;
    }
  }

  &_active,
  &_selected,
  &:hover {
    > div:first-of-type {
      background-color: var(--blue50);
    }

    .buttonsWrapper svg {
      opacity: 1;
    }
  }

  &:not(&_active):hover > div:first-of-type {
    background-color: var(--blue30);
  }

  &_selected:not(&_active) {
    div[class*='Multiselect_value'],
    input {
      font-weight: 600;
    }
  }

  &_withActiveSearch > div:first-of-type {
    background-color: var(--orange50);
  }

  &_readOnly > div:last-child {
    right: unset;
    position: static;
  }
}

.buttonsWrapper {
  display: flex;
  align-self: baseline;
  gap: 12px;
  transition: opacity 0.2s ease-in-out;
  padding-top: 6px !important;
  height: 100%;

  svg {
    opacity: 0;
  }

  > div {
    align-self: baseline;
  }

  &_alwaysVisible svg {
    opacity: 1;
  }
  &_alwaysHidden svg {
    opacity: 0 !important;
  }
}

.spinner {
  flex: 1;
}

.button {
  cursor: pointer;
  width: 15px;
  height: 15px;
  transition: fill 0.2s ease-in-out;
  fill: var(--grey20);
  margin-left: 9px;
  margin-top: 12px;

  &:not(&_disabled):hover {
    fill: var(--color50);
  }

  &_disabled {
    cursor: not-allowed;
  }
}
