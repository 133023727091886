@import "../../../../assets/extend";

.container {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: var(--shadow2dp);
  margin-top: 4px;
  overflow: hidden;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-bottom: 12px;
}

.header {
  display: flex;
  height: 88px;
  flex-direction: row;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
}

.name {
  color: var(--grey100);
  font-family: var(--inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.typeControl {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  color: var(--grey50);
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 88px;
  //border-top: 1px solid var(--grey5);
  border-bottom: 1px solid var(--grey5);
  background: var(--bg10);
}

.body {
  display: flex;
  flex: 1;
  padding: 24px;
  overflow: auto;
  justify-content: center;
  align-items: center;
}

.noDataMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.noDataMessageText {
  font-weight: 700;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -0.025em;
  color: var(--grey10);
  text-transform: uppercase;
}

.footer {
  display: flex;
  height: 72px;
  border-top: 1px solid var(--grey5);
  background: var(--bg10);
  border-radius: 0 0 4px 4px;
  padding: 16px 32px;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
}

.headerTitle, .headerName {
  font-family: var(--inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.headerTitle {
  color: var(--grey100);
}

.headerName {
  color: var(--grey50);
}

.dropdownOverlay {
  @extend %popover-overlay;
  
  border-radius: 8px;
  padding: 8px;
  min-width: 220px;
  max-width: 270px;
  min-height: 96px;
  margin-top: 8px;
}

.downloadButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;

  &:hover {
    background-color: var(--grey5);
  }
}
