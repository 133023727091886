.container {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }

  &:has(input:checked) {
    opacity: 1;
  }

  &_disabled .label {
    color: var(--blue30);
    cursor: not-allowed;
  }

  &_toDelete {
    div[class*="checkmark"] {
      border-color: var(--red70) !important;

      > svg {
        fill: var(--red70) !important;
      }
    }
    .label {
      text-decoration: line-through;
      color: var(--red70) !important;
    }
  }
}

.container:hover .button {
  opacity: 0.5;
}

.label {
  margin-left: 8px;
  user-select: none;
  cursor: pointer;
  transition: color 0.2s;
}

.subItemsLabel {
  margin-left: 4px;
  color: var(--grey30);
}

.button {
  width: 16px;
  height: 16px;
  margin-left: auto;
  opacity: 0;
  fill: var(--grey50);
  cursor: pointer;
  transition: opacity 0.2s;
  user-select: none;
}