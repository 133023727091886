@import "../../../../../../../../assets/extend";

.multiselect {
	display: flex;
	align-items: center;
	height: 1.75rem;
	width: 210px;
	cursor: pointer;
	position: relative;
	border-bottom: 1px solid var(--grey30);
	transition: all 0.2s ease-in-out;

	&::before {
		content: '';
		text-align: right;
		font-size: 14px;
		color: var(--grey30);
		width: 14px;
		height: 14px;
		position: absolute;
		left: 7px;
		//bottom: 8px;
		background-image: url("../../../icons/list-check.svg");
		background-size: 14px;
		background-repeat: no-repeat;
	}

	&::after {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		right: 6px;
		background: url("../../../icons/chevron-down.svg") no-repeat;
		transition: transform 0.2s linear, opacity 0.2s ease-in-out;
	}

	&_active:not(&_disabled) {
		border-bottom-color: var(--grey80);

		&::after {
			transform: scale(1, -1);
		}
	}

	&:not(&_disabled):not(&_active):hover {
		border-bottom-color: var(--grey40);
	}

	&_disabled {
		opacity: 0.8;
		cursor: not-allowed;
		border-bottom-color: transparent;

		.placeholder {
			display: none;
		}

		&::after {
			opacity: 0;
		}
	}

	&_hidden {
		display: none;
	}

	&:not(&_disabled) {
		background-color: var(--grey0);
	}
}

.value {
	max-width: calc(100% - 46px);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 14px;
	height: 15px;
	padding-left: 34px;
}

.placeholder {
	color: var(--grey50);
	font-size: 14px;
}

.count {
	margin-left: 4px;
	color: var(--blue60);
	cursor: pointer;
	font-size: 14px;
	height: 15px;
}

.checkbox {
	background: transparent !important;
}

.overlay {
	@extend %field-overlay;
	position: unset;

	padding: 0;
	max-height: 28px * 10 + 8px;
	min-width: 208px;
	overflow-y: auto;
}

.portalContent {
	z-index: 99999;
}
