@import "../../../assets/extend";

.legal-entity-form {
	@extend %form;

	&__shadow {
		@extend %form__top-shadow;
	}

	&__content {
		@extend %form__content;

		padding: 24px;
	}

	&__row {
		@extend %form__row;

		&_block-closer {
			@extend %form__row_block-closer;
		}
	}

	&__modal {
		min-width: 860px;
		max-width: 1150px;
	}

	&__modal-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;

		> span {
			color: var(--grey70);
			cursor: pointer;
			transition: color 0.2s;

			&:hover {
				color: var(--grey100);
			}
		}
	}

	&__modal-title {
		font-size: 18px;
		font-weight: normal;

		> b {
			font-weight: bold;
			margin-left: 4px;
		}
	}
}
