.screen-header {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;

  &__title {
    color: var(--grey100);
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.022em;
    font-feature-settings: 'ss01' on;

    &_dense {
      font-weight: 500;
      font-size: 16px;
    }
  }

  &__badge {
    margin-right: 16px;
  }

  &__columns {
    margin-right: 32px;
  }
}
