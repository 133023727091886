%label {
  color: #3A3A44;
  font-family: var(--inter);
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.018em;

  &_required {
    &::before {
      content: '*';
      color: var(--red60);
    }
  }

  &_dense {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.033em;
    line-height: 126%;
  }
}

%field-overlay {
  top: 45px;
  left: -2px;
  overflow-y: auto;
  min-width: calc(100% + 4px);
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(58, 58, 68, 0.12), 0 16px 32px rgba(90, 91, 106, 0.12);
  padding: 8px;
  background-color: #fff;
  z-index: 100;

  &:empty {
    display: none;
  }

  &__item {
    border: none;
    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    font-family: var(--inter);
    cursor: pointer;
    background-color: var(--white);
    transition: background-color 0.2s ease-in-out;
    text-align: left;
    justify-content: flex-start;
    gap: 5px;

    &:hover {
      background-color: #EBF2FF;
    }
  }
}

%popover-overlay {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: var(--shadow8dp);
}

%form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 1px;

  &__top-shadow {
    position: absolute;
    z-index: 999;
    height: 20px;
    width: 100%;
    top: 35px;
    box-shadow: 0 4px 8px rgba(58, 58, 68, 0.08), 0 8px 16px rgba(90, 91, 106, 0.12);
    clip-path: inset(1px 0px -15px 0px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0;
    overflow-y: scroll;
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_block-closer {
      margin-bottom: 32px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_block-closer {
      margin-bottom: 32px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--grey0);
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px 0;
    margin-bottom: 32px;

    &__title {
      color: var(--grey50);
      font-weight: 600;
      font-size: 12px;
      line-height: 126%;
      letter-spacing: 0.168em;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
  }
}

%custom-filter {
  width: 320px;
  position: absolute;
  margin-top: 5px;
  left: -60%;
  display: flex;
  flex-direction: column;
  padding: 24px 15px 32px 15px;
  background: var(--white);
  box-shadow: 0 8px 16px rgba(58, 58, 68, 0.12), 0 16px 32px rgba(90, 91, 106, 0.12);
  border-radius: 8px;

  &__header {
    font-size: 14px;
  }

  &__button-group {
    margin-bottom: 24px;
  }

  &__card {
    &__content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 6px 28px 6px 22px;

      &__text {
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }

  &__filter-controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    &__button {
      margin-left: 12px;
      margin-top: 6px;

      &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 143%;
        color: var(--blue0);
        letter-spacing: 0.018em;

        &__outlined{
          color: var(--blue70);
        }
      }
    }
  }
}

%detail-cell-renderer {
  color: var(--blue60);
  cursor: pointer;
}

%callout-overrider {
  white-space: break-spaces;

  b {
    font-weight: 500;
  }

  i {
    font-style: italic;
  }

  > span[class*="bp3-icon"] {
    top: 0.55rem !important;
  }
}
