.container {}

.container input {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border: 1px solid var(--grey100);
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s;

  > svg {
    transition: fill 0.2s;
  }
}

.container input:disabled ~ .checkmark {
  border-color: var(--blue30);
  cursor: not-allowed;

  > svg {
    fill: var(--blue30);
  }
}
