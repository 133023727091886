.header {
  top: 0;
  z-index: 11111;
  height: 56px;
  display: flex;
  position: sticky;
  padding-left: 26px;
  padding-right: 14px;
  background-color: var(--white);
  justify-content: space-between;
  box-shadow: 0 1px 2px rgba(59, 69, 123, 0.12),
    0 2px 4px rgba(92, 107, 192, 0.12);
  $header: &;

  &__pin-button {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px;
    display: flex;
    margin: auto 0;
  }
}
