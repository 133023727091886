/*TABS */
/*FLAT*/
.flat div[class*="tab"][aria-selected="true"] {
  color: var(--color100);
}
.flat div[class*="tab"][aria-selected="true"] > span > svg,
.flat div[class*="tab"][aria-selected="false"]:hover > span > svg {
  fill: var(--color70);
}
.flat div[class*="tab"][aria-selected="false"] {
  color: var(--color70);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.flat div[class*="tab"][aria-selected="false"] > span > svg {
  fill: var(--color30);
}

.flat div[class*="tab"][aria-selected="false"]:hover,
.flat div[class*="tab"][aria-selected="false"]:active {
  color: var(--color100);
  background-color: var(--color0) !important;
  box-shadow: 0px -0.25rem 0px 0px var(--color10) inset !important;
  border-radius: 0.25rem 0.25rem 0 0;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

.flat div[class*="tab-indicator-wrapper"] {
  z-index: 1;
}

.flat div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: var(--color70);
  /*box-shadow: var(--shadow2dp);*/
}

.flat[class*="dense"] div[class*="tab"][aria-selected="false"]:hover,
.flat[class*="dense"] div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 0px -0.125rem 0px 0px var(--color10) inset !important;
}
.flat[class*="dense"]
  div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 0.125rem;
}
/*-----*/
/*FLAT SEGMENTED*/
.flat[class*="segmented"] div[class*="tab-indicator"] {
  height: 0;
}
.flat[class*="segmented"] div[class*="tab-indicator-wrapper"] {
  z-index: 0;
}

.flat[class*="segmented"] div[class*="tab-list"] {
  background: var(--white);
  border-radius: 4px;
}
.flat[class*="segmented"] div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
}
.flat[class*="segmented"] div[class*="tab"][aria-selected="false"]:hover,
.flat[class*="segmented"] div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color0);
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.flat[class*="segmented"] div[class*="tab"][aria-selected="true"] {
  color: var(--color90);
  font-weight: 500;
}
.flat[class*="segmented"] div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--color5);
}

.flat[class*="segmented"]
  div[class*="tab"][aria-selected="false"]
  > span
  > svg {
  fill: var(--color40);
}
.flat[class*="segmented"]
  div[class*="tab"][aria-selected="false"]:hover
  > span
  > svg {
  fill: var(--color90);
}
.flat[class*="segmented"] div[class*="tab"][aria-selected="true"] > span > svg {
  fill: var(--color100);
}

/*END-----*/

/*FILLED*/
.filled div[class*="tab-list"] {
  background: var(--color5);
  border-radius: 4px 4px 0 0;
  /*width: fit-content;*/
}

.filled div[class*="tab"][aria-selected="false"] {
  color: var(--color60);
}

.filled div[class*="tab"][aria-selected="false"]:hover,
.filled div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 1px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

.filled div[class*="tab-indicator-wrapper"] {
  border: none;
  border-radius: 4px 4px 0 0;
  background: var(--color60); /*linear-gradient(90deg, var(--gradient));*/
}

.filled div[class*="tab"][aria-selected="true"] {
  color: var(--white);
}
/*--------------*/
/*FILLED SEGMENTED*/
.segmented.filled div[class*="tab-list"] {
  border-radius: 4px;
  border-bottom: none;
}
.segmented.filled div[class*="tab"][aria-selected="false"] {
  color: var(--grey50);
  border-bottom: none;
}
.segmented.filled div[class*="tab"][aria-selected="false"]:hover {
  color: var(--color60);
}
.segmented.filled div[class*="tab"][aria-selected="false"]:hover {
  border-bottom: none;
  box-shadow: none !important;
}
.segmented.filled div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
}
.segmented.filled div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
}
/*END-----*/

/*SMOOTH*/
.smooth div[class*="tab-list"] {
  background: var(--color0); /*test*/
  border-radius: 4px 4px 0 0;
  /* width: fit-content;*/
}

.smooth div[class*="tab"][aria-selected="true"] {
  color: var(--color70);
}
.smooth div[class*="tab"][aria-selected="false"] {
  box-shadow: none !important;
  color: var(--color40);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

.smooth div[class*="tab"][aria-selected="false"]:hover,
.smooth div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 1px -2px 0px -1px var(--color70) inset !important;
  color: var(--color70);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

.smooth div[class*="tab-indicator-wrapper"] {
  background-color: var(--color5);
  border-radius: 4px 4px 0 0;
}

.smooth div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 3px;
  border-radius: 4px 4px 0 0;
  background-color: var(--color70);
  box-shadow: var(--shadow2dp);
}
.dense.smooth
  div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 1px;
}
/*-----*/
/*SMOOTH SEGMENTED*/
.segmented.smooth div[class*="tab-list"] {
  background: var(--color0);
  border-radius: 4px;
}
.segmented.smooth div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
}
.segmented.smooth div[class*="tab"][aria-selected="false"]:hover,
.segmented.smooth div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color70);
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.segmented.smooth div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--color10);
}
/*END-----*/

/*OUTLINED*/
.outlined div[class*="tab-list"] {
  /*width: fit-content;*/
}
.segmented.outlined div[class*="tab-list"] {
  border: 1px solid var(--color20);
  border-radius: 4px;
}
.outlined div[class*="tab"][aria-selected="true"] {
  color: var(--color70);
  box-shadow: 0px 2px 0px -1px var(--color40) inset,
    2px -1px 0px -1px var(--color40) inset,
    -2px -1px 0px -1px var(--color40) inset !important;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  transform-origin: 0 100%;
  animation: tab-flip 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.segmented.outlined div[class*="tab"][aria-selected="true"] {
  border: none;
  box-shadow: none !important;
  animation: none;
}
.outlined div[class*="tab"][aria-selected="false"] {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  color: var(--color40);
  box-shadow: 1px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.segmented.outlined div[class*="tab"][aria-selected="false"] {
  border-bottom: none;
  color: var(--color50);
  box-shadow: none !important;
}
.segmented.outlined div[class*="tab"][aria-selected="false"] svg {
  color: var(--color30);
}
.outlined div[class*="tab"][aria-selected="false"]:hover,
.segmented.outlined div[class*="tab"][aria-selected="false"]:hover svg {
  color: var(--color70);
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.segmented.outlined div[class*="tab-indicator-wrapper"] {
  border: 1px solid var(--color40);
  border-radius: 4px;
  margin-top: 0;
  background: var(--color0);
}
/*----*/

/*RAISED*/
.raised div[class*="tab-list"] {
  background: var(--color5);
  border-radius: 4px 4px 0 0;
  /*width: fit-content;*/
}
.raised div[class*="tab"][aria-selected="true"],
.raised div[class*="tab"][aria-selected="false"] {
  color: var(--color70);
}
.raised div[class*="tab"][aria-selected="true"] {
  border-radius: 4px 4px 0 0;
  transition: all 180ms ease-out;
}
.raised div[class*="tab"][aria-selected="false"] {
  box-shadow: 0px -3px 0px -1px var(--color20) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.dense.raised div[class*="tab"][aria-selected="false"] {
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.raised div[class*="tab"][aria-selected="false"]:hover,
.raised div[class*="tab"][aria-selected="false"]:active {
  color: var(--color70);
  box-shadow: 0px -3px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.dense.raised div[class*="tab"][aria-selected="false"]:hover,
.dense.raised div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 0px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.raised div[class*="tab-indicator-wrapper"] {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  border-radius: 4px 4px 0 0;
}
.raised div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 2px;
  background-color: var(--color60);
}
.dense.raised
  div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 1px;
}
/*---*/

/*Raised Segmented*/
.segmented.raised div[class*="tab-list"] {
  border-radius: 4px;
  border-bottom: none;
}
.segmented.raised div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
  box-shadow: none !important;
}

.segmented.raised div[class*="tab"][aria-selected="false"]:hover,
.segmented.raised div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  box-shadow: none !important;
  color: var(--color60);
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

.segmented.raised div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
}

/*-----*/

.filled div[class*="tab-indicator"],
.outlined div[class*="tab-indicator"],
.smooth div[class*="tab-indicator"],
.segmented.raised div[class*="tab-indicator"],
.segmented.smooth div[class*="tab-indicator"] {
  height: 0;
}
.flat div[class*="tab"][id*="tab-title"],
.filled div[class*="tab"][id*="tab-title"],
.smooth div[class*="tab"][id*="tab-title"],
.raised div[class*="tab"][id*="tab-title"],
.outlined div[class*="tab"][id*="tab-title"] {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

/*------DIV BOTTOM LINE
height: 3px;
width: 100%;
background: black;
bottom: 0;
position: absolute;
left: 0;
border-radius: 99px;
transform: scaleX(01);
transition: transform 180ms;
*/

@keyframes tab-flip {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
