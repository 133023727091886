@import "../../../../assets/extend";

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  @extend %label;
}

.content {
  display: flex;
  gap: 12px;

  input {
    display: none;
  }

  &:has(input:disabled) > .input {
    cursor: not-allowed;
    > span {
      opacity: 0.3 !important;
    }
  }
}

.uploadedImage {
  border-radius: 4px;
  border: 1px solid var(--grey10);
  object-fit: contain;
}

.input {
  display: flex;
  flex-direction: column;
  background-color: var(--blue0) !important;
  border: 1px dashed var(--blue30);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;

  > span {
    opacity: 0.3;
    transition: opacity 0.2s;
  }

  &:hover > span {
    opacity: 1;
  }
}

.tools {
  display: flex;
  flex-direction: column;
  width: 18px;
  height: 100%;
  gap: 24px;
}

.toolButton {
  cursor: pointer;
  color: var(--grey30);
  transition: color 0.2s;

  &:hover {
    color: var(--grey70);
  }
}

.hint {
  color: var(--grey40);
  font-feature-settings: 'ss01' on;
  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.396px;
}
