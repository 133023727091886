@import "src/assets/extend";

.code-field{
    display: flex;
    flex-direction: column;
    position: relative;
    $field: &;

    &_fullscreen {
        max-width: 100% !important;
        max-height: 100% !important;
        background-color: var(--white);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 16px;
        border-radius: 8px;
        z-index: 999;

        #{$field} {
            &__label {
                margin-top: 0;
            }

            &__input {
                max-height: 100% !important;
                height: 100% !important;
            }

            &__fullscreen-button {
                margin-right: 35px;
                margin-bottom: 30px;
            }
        }
    }
    &__label {
        @extend %label;
        margin-top: 25px;
        &_required {
            @extend %label_required;
        }
    }
    &__input{
        width: 100% !important;
        margin-top: 4px;
        max-height: 400px;
        background: var(--grey0);
        border-radius: 4px;

        &_error {
            box-shadow: 0 0 0 1px var(--red30);
            border: 0!important;
        }
    }
    &__buttons{
        margin-top: 48px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &__cancel{
            margin-right: 24px;
        }
    }
    &__error{
        z-index: 1111;
        .bp3-toast{
            max-width: 440px;
            width: 100%;
        }
        &__icon{
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                margin: 0 !important;
            }
        }
    }
    &__fullscreen-button {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 25px;
        margin-bottom: 20px;
    }
    &__error-helper-text {
        color: var(--red50);
        position: absolute;
        z-index: 1;
        font-size: 10px;
        letter-spacing: var(--letter_spacing_caption);
        font-weight: var(--font_weight_caption);
        animation: shake-horizontal 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        bottom: -1.33rem;
    }
}
