@import "../../../assets/extend";

.form {
  @extend %form;
}

.shadow {
  @extend %form__top-shadow;
}

.content {
  @extend %form__content;
}

.row {
  display: flex;
  gap: 24px;

  &_smallGap {
    gap: 12px;
    margin-top: 14px;
    align-items: flex-end;

    button {
      align-self: auto;
      margin-bottom: 0.125rem;
    }
  }
}

.sectionTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.callout {
  @extend %callout-overrider;

  margin-top: 14px;
}

.hint {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey50);
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.252px;
  margin-top: 14px;
}

.brandingTitle {
  color: var(--grey100);
  font-size: 20px;
  line-height: 158%;
  letter-spacing: 0.15px;
}

.logoRow {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

.fileHint {
  color: var(--grey50);
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.252px;
  margin-top: 14px;
}
