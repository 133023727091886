.container {
  display: flex;
  background-color: var(--white);
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 150px);
  overflow-y: hidden;
  padding: 14px;

  div[class="scrollable-tabs"] {
    overflow: hidden;
  }
  div[class="scrollable-tabs__tabs-panel-wrapper"] {
    padding: 2px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  font-size: 12px;
  color: var(--grey40);
  margin-bottom: 24px;
}

.chipsWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  svg[data-icon="warning-sign"] path {
    fill: var(--orange50);
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--grey60);
}
