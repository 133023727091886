.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.donutChartsWrapper {
  display: flex;
  margin-top: 24px;
  border-top: 1px solid var(--grey10);
  flex: 1;
}

.topSendersWrapper,
.topChatbotsWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 24px;
}


.topSendersWrapper {
  padding-right: 24px;
  border-right: 1px solid var(--grey10);
}

.topChatbotsWrapper {
  padding-left: 24px;
}

.title {
  color: var(--grey100);
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
}

.subtitle {
  color: var(--grey50);
  font-size: 14px;
  line-height: 146%;
}

.noData {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: var(--grey50);
  font-size: 24px;
  gap: 14px;
}

.channelsWrapper {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
  padding-bottom: 24px;
  min-height: 94px;
}

.channelItem {
  display: flex;
  flex-direction: column;
  min-width: 60px;
  width: 100%;
  padding-right: 14px;
  transition: max-width 0.2s linear;

  &:not(:last-of-type) {
    border-right: 1px dashed var(--grey10);
  }

  span:nth-child(1) {
    color: var(--grey50);
    font-size: 12px;
    line-height: 140%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:nth-child(2) {
    font-weight: 500;
    line-height: 130%;
  }

  div {
    height: 8px;
    border-radius: 10px;
    width: 100%;
    margin-top: 24px;
  }
}
