.json-file-field {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--grey5);
	cursor: pointer;

	&__text {
		letter-spacing: 0.0275em;
		font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: var(--grey40);

		&_filled {
			color: var(--grey100);
		}

		&_error {
			color: var(--red50);
		}
	}

	&__button {
		cursor: pointer;

		&:first-of-type {
			margin-right: 14px;
		}
	}

	&__input {
		display: none;
	}
}
