.contact-array-field {
	display: flex;
	flex-direction: column;

	&__row {
		display: flex;
		align-items: flex-end;

		&:not(:last-of-type) {
			margin-bottom: 24px;
		}
	}

	&__input {
		width: 100%;

		&:first-of-type {
			margin-right: 12px;
		}

		&:last-of-type {
			margin-left: 12px;
		}
	}

	&__delete-button {
		margin: 0 0 12px 8px;
		cursor: pointer;
		//display: flex;
	}
}
