@import "../../../../../assets/extend";

.overlay {
  @extend %popover-overlay;

  margin-top: 8px;
}

.menuItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;

  &:not(&_disabled):hover {
    background-color: var(--grey5);
  }

  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.itemIcon {
  display: flex;
  justify-content: center;
  margin-right: 8px;
}
