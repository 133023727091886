.scaleConfig {
	display: flex;
	flex-direction: column;
	gap: 4px;
	transition: all 0.2s linear;
}

.row {
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	padding-right: 24px;

	&:hover .removeButton,
	&:hover .addingControl {
		opacity: 1;
	}

	&:first-of-type .addingControl {
		display: none;
	}
}

.addingControl {
	display: flex;
	align-items: center;
	position: absolute;
	top: -9.5px;
	left: -15.5px;
	right: 24px;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.2s ease-out 0.2s;

	> svg {
		fill: var(--grey20);
		transition: fill 0.3s ease-in-out;
		width: 15px;
		height: 15px;
	}

	&:hover {
		> svg {
			fill: var(--blue30);
		}

		.line::after {
			right: 0;
		}
	}
}

.removeButton {
	position: absolute;
	cursor: pointer;
	right: 0;
	opacity: 0;
	transition: opacity 0.2s ease-in-out 0.2s, fill 0.2s ease-in-out;
	width: 16px;
	height: 16px;
	fill: var(--grey20);

	&:hover {
		fill: var(--red50);
	}
}

.line {
	position: relative;
	height: 2px;
	width: 100%;
	background-color: var(--grey20);

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 100%;
		background-color: var(--blue30);
		transition: right 0.3s linear;
	}

	&:hover::after {
		right: 0;
	}
}

.infinity {
	color: var(--grey50);
	font-size: 24px;
	font-weight: 100;
	margin-bottom: 4px;
	line-height: 24px;
	margin-left: auto;
	margin-right: 7px;

	&_disabled {
		margin-left: 2px;
	}
}

.input {
	border: none;
	background-color: transparent;
	color: var(--color100);
	transition: all 0.2s ease-in-out;
	border-bottom: 1px solid var(--grey30);
	height: 1.75rem;
	padding-right: 6px;
	font-size: 14px;
	letter-spacing: 0.033em;
	width: 101px;
	text-align: right;
	font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(&_error):not(:disabled):not(:focus):hover {
		border-bottom-color: var(--grey40);
	}
	&:not(&_error):focus {
		border-bottom-color: var(--grey80);
	}

	&:disabled {
		opacity: 0.8;
		border-bottom-color: transparent;
		pointer-events: none;
	}

	&:not(:disabled) {
		background-color: var(--grey0);
	}

	&_error {
		border-bottom-color: var(--red50);

		&::placeholder {
			color: var(--red50);
		}
	}
}
