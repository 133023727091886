@import "src/scss/variables";

.container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 12px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.5s;

  &_disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:not(&_disabled):hover {
    opacity: 1;
  }

  @media (max-width: $screen-M) {
    height: 18px;
  }
}
