.run-report-block {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;

  &__toaster {
    margin-left: 20px;
  }

  &__title {
    display: flex;
    flex: 1;
    margin-top: 4px;
  }

  &__tabs {
    margin-left: 48px;
  }

  .screen-header__columns {
    margin-right: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__button {
    padding: 9.5px 12px !important;
    border-radius: 4px !important;
  }

  &__button-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    color: var(--blue0);
    letter-spacing: 0.022em;
    text-transform: none;
  }

  &__tab {
    border: 0;
    background-color: transparent!important;
    letter-spacing: 0.018em;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    color: var(--blue100);
  }

  &__tab-content {
    display: flex;
    align-items: center;
  }

  &__panel {
    border: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em 0 0 0;
  }

  &__tab-body {
    display: flex;
    padding: 66px 2px 0 2px;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__body {
    position: absolute;
    display: flex;
    left: 0;
    flex: 1;
    width: 100%;
    height: 100%;
    padding-bottom: 66px;
    overflow-y: auto;
  }

  &__report-sidebar {
    background-color: var(--white);
    flex: 1;
    margin-right: 16px;
    border-radius: 8px;
    margin-bottom: 56px;
    overflow-y: auto;
  }

  &__tabs {
    margin: 16px 16px 16px 24px;
  }

  &__input {
    margin: 0 16px 28px 16px;
  }

  &__list {
    margin: 28px 0 16px 16px;
  }

  .RRT__container {
    position: static;
    margin-right: 275px;
  }

  //styles for lib tabs
  .RRT__tabs {
    position: absolute;
    top: 10px;
    margin-left: 100px;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  }

  .RRT__inkbar-wrapper {
    margin-left: 100px;
  }

  .RRT__inkbar {
    width: calc(100% - 10px);
    height: 2px;
    background: var(--blue70);
    border-radius: 2px;
    transition: height, transform, width;
    transition-property: height, transform, width;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0s, 0s, 0s;
  }

  .RRT__showmore {
    border: 0;
    background-color: transparent;
  }

  .RRT__showmore-label {
    color: var(--blue70);
    font-size: 25px;
    padding: 0;
    background-color: transparent;
  }

  .RRT__showmore-list {
    background-color: var(--white);
    padding: 8px;
    box-shadow: 0 4px 8px rgba(58, 58, 68, 0.16), 0 8px 16px rgba(90, 91, 106, 0.16);
    border-radius: 0 0 8px 8px;

    .RRT__tab--selected {
      background-color: var(--blue5) !important;
      border-radius: 4px;
    }
  }
}
