@import "src/assets/extend";

.agreements-form {
  @extend %form;

  &__shadow {
    @extend %form__top-shadow;
  }

  &__spinner {
    height: 100vh;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }

  &__agreement-top-block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__agreement-vesrion-block {
    display: flex;
    flex-direction: row;
    gap: 14px;
    padding-top: 24px;
    margin-bottom: 24px;
    align-items: center;
  }

  &__agreement-info {
    border-bottom: 1px solid var(--grey5);
    padding: 0 24px 24px;
    margin-bottom: 24px;
  }

  &__agreement-block-wrapper {
    padding: 0 24px 0;
  }

  &__date-row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    > div {
      &:first-of-type {
        margin-right: auto;
      }
    }
  }

  &__content {
    @extend %form__content;
    padding: 24px 0 0;
  }

  &__row {
    @extend %form__row;
    align-items: flex-end;

    &_block-closer {
      @extend %form__row_block-closer;
    }
  }

  &__block {
    @extend %form__block;
    margin-bottom: 0;

    &__title {
      @extend %form__block__title;
    }
  }

  &__modal {
    min-width: 860px;
    max-width: 1150px;
    padding: 24px;
  }

  &__modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    > span {
      color: var(--grey70);
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: var(--grey100);
      }
    }
  }

  &__modal-title {
    font-size: 18px;
    font-weight: normal;

    > b {
      font-weight: bold;
      margin-left: 4px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    margin: auto 0 1px 14px;
    gap: 8px;
  }
}
