@import "src/assets/extend";

.text-field {

  &_new-design {
    & > input {
      font-size: 14px !important;
    }

    & > input:not(:hover) {
      box-shadow: 0 0 0 1px var(--grey5) !important;
    }
  }

  &_zero-padding {
    > input {
      padding: 0 !important;
    }
  }

  &__label {
    &_new-design {
      color: var(--grey30);
    }

    @extend %label;

    &_required {
      @extend %label_required;
    }

    &_dense {
      @extend %label_dense;
    }
  }

  &__label-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    pointer-events: auto;
  }

  &__copy-button {
    padding-top: 10px;
    padding-right: 10px;
    cursor: pointer;
  }

  &__helper-icon:hover {
    fill: var(--grey50);
  }
}
