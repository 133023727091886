.outside {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: var(--inter);
  line-height: 150%;
  letter-spacing: 0.44px;
  padding: 10px 13px;
  border-radius: 40px;
  color: var(--grey50);
  border: 1px solid var(--grey10);
  font-style: normal;
  font-weight: 400;
}

.inside {
  border: none;
  border-radius: 0;
  padding: 0 14px 0 0;

  &:not(:last-child) {
    border-right: 1px solid var(--grey10);
  }
}

.icon {
  margin-right: 6px;
}

.copyIcon {
  margin-right: 6px;
  cursor: pointer;
}

.buttonAction {
  padding-right: 4px;
  border-right: 1px solid var(--grey10);
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 4px;
  color: var(--grey50);

  a {
    color: var(--grey50);
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 50px;
    margin-right: 6px;
  }
}

.urlActionContainer {
  display: flex;
}

.urlContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.action {
  color: var(--grey100);
}
