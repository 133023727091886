.table-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  //height: calc(100vh - 100px);

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__wrapper {
    display: flex;
    flex: 1;
    background-color: #fff;
    border-radius: 4px;
    overflow-x: auto;
  }

  &__pagination {
    display: flex;
    padding-top: 9px;
    border-top: 1px solid #9696A0;
    width: 100%;
    justify-content: flex-end;

    &_new-design {
      padding-top: 18px;
      border-top: none;
    }
  }
}
