@import "../../../../../../assets/extend";

.dropdown {}

.overlay {
  @extend %field-overlay;

  position: unset;
  padding: 0;
  font-size: 12px;
  min-width: 238px;
  overflow-y: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 54px * 4;

  &_services {
    max-height: 28px * 5;
  }
}

.sectorTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey50);
  padding: 8px 6px;

  &:first-of-type {
    border-bottom: 1px solid var(--grey10);
  }

  &:last-of-type {
    border-top: 1px solid var(--grey10);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #EBF2FF;
    }
  }

  &_open {
    background-color: #EBF2FF;
  }
}

.additionalLabel {
  display: flex;
  color: var(--grey50);
  gap: 4px;

  &:not(:empty):before {
    content: '-';
    color: var(--grey100);
  }
}

.overlayItem {
  @extend %field-overlay__item;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 6px;
  border-radius: 0;
  gap: 6px;
  min-height: unset;

  &_row {
    flex-direction: row;
    gap: 4px;

    > span:first-of-type {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 300px;
    }
    > span[class*="bp3-icon"] {
      margin-left: auto;
      color: var(--grey50);
    }
  }

  &_open {
    background-color: #EBF2FF;
  }
}

.search {
  display: flex;
  align-items: center;
  padding: 7px 6px;
  gap: 8px;
  border-bottom: 1px solid var(--grey10);

  > input {
    font-size: 12px;
    border: none;
    width: 100%;
    padding: 0;
  }
}
