@import "../../../../assets/extend";

.form {
  @extend %form;
}

.shadow {
  @extend %form__top-shadow;
}

.content {
  @extend %form__content;
  padding-bottom: 12px;
  gap: 16px;
}

.callout {
  margin-bottom: 16px;
}
