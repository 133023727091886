/*SMOOTH STYLE*/
.smooth {
  background: var(--white);
  box-shadow: var(--greyShadow8dp);
  padding-top: 60px;
}

.smooth span[class*="icon"] {
  color: var(--color70);
}
/* END STYLE */
