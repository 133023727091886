/* DIALOG TYPES*/
/*--DEFAULT--*/
.default {
  width: 98%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

.header {
  display: flex;
  margin-bottom: 24px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  color: var(--grey100);
  letter-spacing: 0.0275em;
  font-size: 16px;
  line-height: 150%;
}

.stickyHeader{
  box-shadow: 0 4px 8px rgb(58 58 68 / 8%), 0 8px 16px rgb(90 91 106 / 12%);
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 11;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 16px 16px 0;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  margin-bottom: 0;
}
.header h5 {
  color: var(--grey100) !important;
  font-family: var(--inter) !important;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
}
.header > span[class*='icon'] {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}
.footer {
  margin-top: 3rem;
}
.left-button {
  float: left;
}
.right-button {
  float: right;
}

.dense {
  width: 98%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
}
.dense.header {
  margin-bottom: 0.75rem;
}
.dense.header > span[class*='icon'] {
  margin-right: 0.75rem;
}
.dense.footer {
  margin-top: 1.75rem;
}

.close-button,.menu-button {
  cursor: pointer;
}

.close-button svg path {
  fill: var(--grey70);
}

.menu-button svg path {
  fill: var(--grey70);
}

.menu-dropdown {
  min-height: 44px;
}

.dialog-header-controls {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-left: auto;
  gap: 10px;
}

.portal {
  z-index: 2000;
}
