@import "src/assets/extend";

.date-picker-field {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_disabled {
    cursor: not-allowed;
  }

  &__label {
    @extend %label;

    &_required {
      @extend %label_required;
    }
  }

  &__tooltip {
    max-width: 400px;
  }

  &__label-container {
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;

    .bp3-popover-wrapper {
      display: flex;
    }
  }

  &__label-tooltip-icon {
    display: flex;
    cursor: pointer;
  }

  &__content {
    position: relative;

    &__input {
      position: relative;
      width: 100%;

      &_highlighted {
        color: var(--blue50);
      }
    }

    &__icon {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
    }

    &__overlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      padding: 16px 8px;
      background-color: #fff;
      box-shadow: 0 8px 16px rgba(58, 58, 68, 0.12), 0 16px 32px rgba(90, 91, 106, 0.12);

      &__button {
        margin-right: 8px;
        align-self: flex-end;
        &__text {
          font-size: 14px;
          font-weight: 500;
          line-height: 143%;
          color: var(--blue0);
          letter-spacing: 0.018em;
        }
      }

      &_dense {
        width: 320px;

        div[class*="DayPicker-Day"] {
          text-align: center;
          vertical-align: middle;
        }

        div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
          display: inline-block;
          vertical-align: top;
        }

        div[class*="DayPicker-Day"],
        div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
          width: 1.7rem;
          height: 1.7rem;
          line-height: 1.7rem;
        }

        div[class*="DayPicker-Month"] {
          border-collapse: separate!important;
          border-spacing: 0.8rem 0!important;
        }

        div[class*="DayPicker-Weekday"] {
          height: 1.5rem;
        }

        div[class*="bp3-timepicker-input-row"] {
          padding: 0!important;
        }

        div[class*="bp3-timepicker"] {
          margin-top: 0;
          margin-bottom: 0;
        }

        div[class*="datepicker-caption"] {
          height: 1.2rem;
        }

        div[class*="datepicker-navbar"] {
          height: 1.5rem;
        }

        div[class*="datepicker-navbar"] > button {
          width: 1.5rem;
          height: 1rem;
        }

        div[class*="datepicker-month-select"] > select,
        div[class*="datepicker-year-select"] > select {
          height: 1.5rem;
        }

        div[class*="datepicker-month-select"] span > svg,
        div[class*="datepicker-year-select"] span > svg {
          height: 1.5rem;
          width: 1rem;
        }
      }
    }
  }

  &__portal {
    z-index: 99999;
  }
}

.bp3-timepicker-input-row {
  border: 1px solid var(--blue30);
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 5px 0 !important;
  height: auto !important;
}
.bp3-timepicker-input {
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  font-family: var(--inter);
  color: var(--blue100) !important;
}

.bp3-popover2-placement-left,.bp3-popover2-placement-right  {
  display: flex;

  .bp3-popover2-arrow-fill {
    fill: white;
  }
  .bp3-popover2-arrow-border {
    fill: transparent;
  }
  .bp3-popover2-arrow {
    height: 30px;
    position: absolute;
    width: 30px;
    &:before {
      height: 20px;
      margin: 5px;
      width: 20px;
      border-radius: 2px;
      content: "";
      display: block;
      position: absolute;
      transform: rotate(45deg);
    }
  }
}

.bp3-popover2-placement-left {
  margin-right: 36px;

  .bp3-popover2-arrow {
    margin-right: 36px;
  }
}
