.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.cardsSpinner {
  margin-top: 24px;
  height: 184px;
}

.recordsBlock {
  display: flex;
  flex: 1;
  margin-top: 24px;
  border-top: 1px solid var(--grey10);
}

.financeOperationsWrapper,
.servicesWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 24px;
}

.financeOperationsWrapper {
  padding-right: 24px;
  border-right: 1px solid var(--grey10);
}

.servicesWrapper {
  padding-left: 24px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.tableTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
  }

  span:nth-child(2) {
    font-size: 12px;
    color: var(--grey40);
  }
}

.table {
  display: grid;
  grid-auto-rows: min-content;
  width: 100%;
  overflow: auto;
  border: 1px solid var(--grey5);
  padding-bottom: 20%;
  margin-top: 24px;
}

.header {
  display: contents;
}

.header > div {
  grid-column: auto;
  padding: 0 8px;
  white-space: nowrap;
  background-color: #f2f2f2;
  color: var(--grey50);
  position: sticky;
  top: 0;
  z-index: 2;
  overflow: hidden;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:last-child {
    right: 0;
    z-index: 3;
  }

  > span {
    cursor: pointer;
    color: var(--grey40);
    transition: color 0.2s;
    margin-left: 4px;

    &:hover {
      color: var(--grey70);
    }
  }
}

.row {
  display: contents;

  > div {
    grid-column: auto;
    padding: 6px;
    display: flex;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
    min-height: 42px;
    white-space: nowrap;

    &:last-of-type {
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }

  &_active,
  &:hover {
    .controlsWrapper {
      opacity: 1;
    }
  }
}
