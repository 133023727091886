@import "../../../../assets/extend";

.form {
  @extend %form;
}

.shadow {
  @extend %form__top-shadow;
}

.content {
  @extend %form__content;

  gap: 24px;
}

.row {
  display: flex;
  align-items: center;
  gap: 24px;
}
