@import "src/scss/variables";

.ag-table {
  width: 100%;
  background-color: var(--on-accent);
  user-select: initial !important;

  &__loading-wrapper {
    width: 100%;
    height: 100%;
  }

  .ag-center-cols-container {
    min-width: 100%;
  }

  .ag-header-cell-text {
    font-size: 14px;
  }

  .ag-cell-value {
    color: var(--grey100);
  }

  .ag-cell-auto-height {
    height: auto;
  }

  .ag-header-row,
  .ag-row {
    .ag-selection-checkbox,
    .ag-header-select-all {
      display: flex;
      margin-right: 14px;
      background-color: transparent;

      .ag-checkbox-input-wrapper {
        border-radius: 1px;
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border-color 0.3s ease-in-out;
        background-color: transparent;
        position: relative;
        box-shadow: 0 0 0 1px var(--grey30);

        &.ag-checked {
          box-shadow: none;

          &::after {
            position: absolute;
            content: '';
            display: flex;
            width: 11px;
            height: 11px;
            background: var(--blue60);
            background: url("../../assets/icons/check.svg") center no-repeat;
            background-size: 11px;
          }
        }

        .ag-checkbox-input {
          width: 11px;
          height: 11px;
          z-index: 2;
          cursor: pointer;
          opacity: 0;
        }
      }
    }
  }

  .ag-react-container:has(> div[class*="RowDetails_container"]) {
    height: 100%;
  }

  .ag-header-row {

    .ag-header-cell[aria-sort="ascending"],
    .ag-header-cell[aria-sort="descending"] {
      background-color: var(--grey0);
    }

    .ag-header-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 8px 10px;

      &.ag-header-cell-sortable {
        cursor: default;
      }

      //.ag-header-cell-text {
      //  text-align: left;
      //}

      .ag-header-cell-resize {
        &::after {
          content: "";
          position: absolute;
          z-index: 1;
          display: block;
          left: calc(50% - 1px);
          width: 1px;
          height: 30%;
          top: 35%;
          //background-color: var(--basic);
        }
      }

      .ag-react-container {
        width: 100%;
      }
    }
  }

  .ag-header {
    border-bottom: 2px solid var(--grey50);
    .ag-pinned-right-header .ag-column-header {
      justify-content: flex-end;
    }
  }

  // color border
  .ag-row,
  .ag-header-row .ag-header-cell {
    //box-shadow: inset 0px -1px 0px #E6EBEF, inset -1px 0px 0px rgba(239, 242, 245, 0.5);
    border-bottom: 1px solid var(--shadow-grey);
  }

  .ag-row .ag-cell,
  .ag-header-row .ag-header-cell, {
    &:not(:last-of-type) {
      border-right: 1px solid var(--shadow-grey);
    }
  }

  .ag-row {
    transition: background 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    border-bottom: 1px solid var(--grey10);

    &.ag-row-hover {
      background-color: var(--grey5);
      border-bottom: 1px solid var(--grey50);
    }

    &.ag-row-selected {
      background: var(--blue0);
    }

    .ag-cell {
      position: unset;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 100%;
      align-items: center;
      padding: 8px;
      user-select: text;
      cursor: pointer;
      font-size: 12px;
      letter-spacing: 0.02em;
      font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;

      @media (max-width: $screen-M) {
        font-size: 12px;
        letter-spacing: 0.033em;
      }

      .ag-react-container {
        display: flex;
        align-items: center;
        height: 100%;
      }

      // for dropdown cell
      &.ag-cell-dropdown {
        overflow: visible;
      }

      &.appearing-cell {
        display: none;
      }
    }

    &.ag-row-hover {
      //@include themify() {
      //  background: t('color-dark-grey-opacity');
      //}

      .appearing-cell {
        display: unset;
      }
    }

    // for dropdown cell
    z-index: 0;

    // for dropdown cell
    &.ag-row-focus {
      z-index: 1;
    }
  }

  .ag-floating-bottom-container .ag-row {
    border-bottom: none;
    border-top: 1px solid var(--grey10);
  }

  .ag-header-viewport,
  .ag-pinned-right-header {
    //@include themify() {
    //  border-bottom: 1px solid t('color-grey');
    //}
  }

  .ag-menu {
    overflow-y: unset;
    width: 258px;
  }

  &_without-selected {
    .ag-row {
      &.ag-row-selected {
        background: unset !important;
      }

      &.ag-row-hover {
        background: var(--dark);
      }
    }
  }

  .ag-react-container {
    //@include themify() {
    //  color: t('color-white');
    //}
  }
}

.ag-header-cell-filtered {
  background-color: #f7f7fa;
}
//
//.c-dark-theme .ag-row:nth-child(odd) {
//  background-color: rgba(255, 255, 255, 0.075);
//}
