.servicesLayout {
	display: flex;
	flex-direction: column;

	div[class="pagination"] {
		width: 100%;
	}
}

.headerButtons {
	display: flex;
	gap: 16px;

	button {
		white-space: nowrap;
	}
}

.spinner {
	height: 100%;
}
