.serviceTab {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: calc(100vh - 150px);
	background-color: var(--white);
	padding: 14px;
	overflow-y: hidden;
}

.spinner {
	display: flex;
	flex: 1;
}

.headerRow {
	display: flex;
	align-items: center;
	gap: 24px;
}

.headerItem {
	display: flex;
	gap: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 143%;
	letter-spacing: 0.018em;
	font-feature-settings: 'ss01' on;

	span:nth-child(1) {
		color: var(--grey50);
	}
}

.buttonsWrapper {
	display: flex;
	align-items: center;
	margin-left: auto;
	gap: 8px;
}

.contentTitle {
	color: var(--grey50);
	font-size: 14px;
	letter-spacing: 0.022em;
	font-feature-settings: "ss01" on;
	padding: 8px 0;
}

.content {
	display: flex;
	margin-top: 24px;
	gap: 16px;
	flex: 1;
	overflow: hidden;
}

.listWrapper {
	display: flex;
	flex-direction: column;
	min-width: 272px;
	width: 1px;
	border-radius: 4px;
}

.list {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	margin-top: 4px;
}

.addButton {
	width: 100%;
	min-height: var(--s40);
}

.table {
	display: flex;
	flex: 1;
}

.animatedActionCell svg {
	animation: pulse-color 1s 2;
}

.animatedRow > div:not(:first-child):not(:last-child) {
	animation: bg-change 1s 2;
}

@keyframes bg-change {
	0% {
		background-color: var(--white);
	}
	50% {
		background-color: var(--blue5);
	}
	100% {
		background-color: var(--white);
	}
}
@keyframes pulse-color {
	0% {
		fill: var(--grey30);
	}
	20% {
		fill: var(--color30);
	}
	40% {
		fill: var(--color40);
	}
	50% {
		fill: var(--color50);
	}
	80% {
		fill: var(--color40);
	}
	90% {
		fill: var(--color30);
	}
	100% {
		fill: var(--grey30);
	}
}
