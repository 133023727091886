.cell {
	display: flex;
	flex-direction: column;
	gap: 2px;
	position: relative;
}

.link {
	position: absolute;
	width: 10px;
	bottom: calc(100% - 16px);
	border-bottom: 1px solid var(--grey20);
	border-left: 1px solid var(--grey20);
	top: 0;
	transition: border-bottom-color 0.2s, border-left-color 0.2s;
}

.linkToSibling {
	position: absolute;
	width: 1px;
	bottom: 0;
	top: 0;
	background-color: var(--grey20);
	transition: background-color 0.2s ease-in-out;
}

.row {
	display: flex;
	gap: 10px;
	position: relative;
	margin-left: 6px;
}

.button,
.cleanButton {
	cursor: pointer;
	width: 15px;
	height: 15px;
	transition: fill 0.2s ease-in-out;
	fill: var(--grey20);

	&:not(&_disabled):hover {
		fill: var(--color50);
	}

	&_disabled {
		cursor: not-allowed;
	}
}

.cleanButton {
	position: absolute;
	right:  4px;
	opacity: 0;
	transition: opacity 0.2s ease-in-out 0.2s;

	&:not(&_disabled):hover {
		fill: var(--red50);
	}
}

.searchWrapper {
	display: flex;
	position: absolute;
	left: 50px;
	background-color: var(--white);
	transition: left 0.2s linear;

	&:focus-within,
	&:has(input:not([value=""])) {
		left: 0;

		> .button {
			fill: var(--color50);
		}
		> .cleanButton {
			opacity: 1;
		}
		> .searchBorder {
			max-width: 180px;
		}

		input {
			max-width: 180px;
		}
	}

	&:focus-within .searchBorder {
		background-color: var(--grey40);
	}

	> .button {
		margin-right: 8px;
	}

	input {
		width: 100%;
		padding: 0 24px 2px 6px;
		font-size: 14px;
		border: none;
		max-width: 0;
		transition: max-width 0.3s linear;
		color: var(--grey50);
		font-weight: normal !important;
	}
}
.searchBorder {
	position: absolute;
	bottom: -1px;
	max-width: 0;
	width: 100%;
	height: 1px;
	left: 23px;
	background-color: var(--grey20);
	transition: max-width 0.3s linear, background-color 0.3s ease-in-out;
}

.input {
	border: none;
	height: 1.75rem;
	padding-left: 6px;
	font-size: 14px;
	letter-spacing: var(--letter_spacing_body);
	font-weight: var(--font_weight_body);
	background-color: transparent;
	//color: var(--red50);
	border-bottom: 1px solid var(--grey30);
	transition: all 0.2s ease-in-out;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(&_error):not(:disabled):not(:focus):hover {
		border-bottom-color: var(--grey40);
	}
	&:not(&_error):focus {
		border-bottom-color: var(--grey80);
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.8;
		border-bottom-color: transparent;
		pointer-events: none;
	}

	&:not(:disabled) {
		background-color: var(--grey0);
	}

	&_error {
		position: relative;
		border-bottom-color: var(--red50);

		&::placeholder {
			color: var(--red50);
		}
	}
}

.error {
	color: var(--red50);
	position: absolute;
	z-index: 1;
	font-size: 10px;
	letter-spacing: var(--letter_spacing_caption);
	font-weight: var(--font_weight_caption);
	animation: shake-horizontal 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	bottom: -1px;
}
