.file-field {
	width: 100%;

	&__right-element {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-top: 12px;
		margin-right: 0.5rem;
	}

	input {
		cursor: inherit;
	}

	input[type="file"] {
		display: none;
	}
}
