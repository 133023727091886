@import "../../../../assets/extend";

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.label {
  @extend %label;
}

.content {
  display: flex;
  gap: 12px;

  input {
    display: none;
  }

  &:has(input:disabled) > .input {
    cursor: not-allowed;
    > span {
      opacity: 0.3 !important;
    }
  }
}

.contentLoading {
  display: inline-block;
  height: 40px;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite ease-in-out;
  border-radius: 0.25rem;

  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}


.container {
  width: 100%;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.uploadedImage {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid var(--grey10);
  object-fit: contain;
}

.input {
  display: flex;
  flex-direction: column;
  background-color: var(--blue0);
  border: 1px dashed var(--blue30);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  color: var(--blue70);
  height: 40px;
  width: 100%;

  > span {
    opacity: 0.3;
    transition: opacity 0.2s;
  }

  &:hover > span {
    opacity: 1;
  }
}

.tools {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.toolButton {
  cursor: pointer;
  color: var(--grey30);
  transition: color 0.2s;

  &:hover {
    color: var(--grey70);
  }
}

.hint {
  color: var(--grey40);
  font-feature-settings: 'ss01' on;
  font-family: var(--inter), sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.396px;
  margin-top: 4px;
}
