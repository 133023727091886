.text-cell-renderer {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 1px;

	&__content {
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		padding: 16px 8px;
		background-color: #fff;
		box-shadow: 0 8px 16px rgba(58, 58, 68, 0.12), 0 16px 32px rgba(90, 91, 106, 0.12);
		max-width: 250px;
		max-height: 300px;
		overflow: auto;

		&__item {
			font-family: var(--inter);
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 143%;
			letter-spacing: 0.018em;
			display: flex;
		}
	}

	&__overlay {
		margin-right: 0;
	}
}
