.mcc-mnc {
  display: flex!important;
  flex-direction: column;
  padding: 16px 0;
  &__layout{
    flex: 1;
    display: flex;
    max-height: calc(100vh - 450px);
    min-height: 500px;
  }
  &__prefixes {
    flex: 1;
    display: flex;
    max-height: calc(100vh - 450px);
    min-height: 500px;
  }
  &__wrapper{
    box-shadow: 0px 12px 24px rgba(27, 78, 163, 0.08),
      0px 24px 48px rgba(41, 121, 255, 0.08);
    padding: 0 24px;
  }
  &__pagination {
    padding-bottom: 18px;
  }
  &__header{
    padding: 0 24px;
  }
}
