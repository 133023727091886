.tree-dropdown-field {
  display: flex;
  flex-direction: column;
  max-height: 20px;
  overflow-y: hidden;
  transition: max-height 0.3s;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  $dropdown: &;

  &_selected {
    color: var(--blue100);
    font-weight: 500;
    max-height: 100%;
    overflow-y: visible;

    #{$dropdown}__icon {
      transform: rotate(90deg);
    }
  }


  &__icon {
    transition: transform 0.3s ease-in-out;
    margin-right: 12px;
  }

  &__sub-item {
    margin: 10px 0 0 22px;
    padding: 4px 0 4px 8px;
    border: 0;
    background: var(--white);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.018em;

    &_selected {
      background: var(--blue5);
      box-shadow: inset -3px 0px 0px var(--blue70);
      border-radius: 4px 0 0 4px;
    }
  }

  &__button,
  &__sub-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    letter-spacing: 0.15px;
    text-align: left;
  }
}
