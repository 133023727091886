.image-field {
	display: flex;
	flex-direction: column;

	&__wrapper {
		display: flex;
		flex-direction: column;
		height: 164px;
		background-color: var(--blue0);
		border: 1px dashed var(--blue30);
		border-radius: 4px;
		cursor: pointer;
		position: relative;
		align-items: center;
		justify-content: center;

		&_uploaded {
			border-color: transparent;
			cursor: initial;
			background-color: var(--grey0);
		}

		&_border {
			border-style: solid;
			cursor: initial;
		}
	}

	&__container {
		display: flex;
		flex-direction: row;
		gap: 12px;
	}

	&__controls {
		display: flex;
		flex-direction: column;
		gap: 24px;

		span {
			cursor: pointer;
		}

		span:hover {
			svg path {
				fill: var(--grey70)
			}
		}
	}

	&__label {
		font-weight: 400;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: 0.018em;
		color: var(--grey50);
		margin-bottom: 2px;
	}

	&__row {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	&__text {
		letter-spacing: 0.022em;
		font-feature-settings: 'ss01' on;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: var(--blue30);
	}

	&__remove {
		position: absolute;
		right: 14px;
		top: 14px;
		cursor: pointer;
	}

	&__image {
		display: flex;
		height: 164px;
		width: 100%;
		object-fit: cover;
		border-radius: 4px;
	}

	&__chips {
		color: var(--white) !important;
		border-radius: 4px !important;
		margin-bottom: 2px;
		letter-spacing: 0.033em !important;
		font-feature-settings: 'ss01' on !important;
		font-weight: 400 !important;
		font-size: 12px !important;
		text-transform: capitalize !important;
	}

	&__input {
		display: none;
	}
}
