.saved-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 12px 0 24px;
  overflow-x: hidden;
  scrollbar-width: none !important;
  $saved-tabs: &;

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  &__counter {
    background-color: var(--grey70);
    color: var(--grey0);
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.018em;
    font-size: 14px;
    line-height: 143%;
    margin-right: 8px;
    border-radius: 99px;
    padding: 0 7px;
    height: 20px;
  }

  &__tabs-list {
    position: fixed;
    top: 53px;
    display: flex;
    flex-direction: column;
    min-width: 240px;
    max-height: calc(32px * 10 + 16px);
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(58, 58, 68, 0.24), 0 2px 4px rgba(90, 91, 106, 0.24);
    padding: 8px 0;
    overflow-y: auto;
    z-index: 9999;
  }

  &__tab-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px 6px 24px;
    font-size: 14px;
    line-height: 143%;
    color: var(--grey70);
    text-transform: capitalize;
    border-right: 4px solid transparent;

    &:hover {
      background-color: var(--blue0);
    }

    &_active {
      background-color: var(--blue0);
      border-right-color: var(--blue70);
    }
  }

  &__tab-item {
    display: flex;
    height: 100%;
    padding: 6px 12px 0;
    border-bottom: 4px solid transparent;
    color: var(--blue70);
    border-radius: 4px 4px 0 0;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.022em;
    position: relative;
    white-space: nowrap;

    &:hover {
      border-bottom-color: var(--blue10);

      #{$saved-tabs}__tabs-list {
        display: flex;
      }
    }

    &_active {
      color: var(--blue100);
      background-color: var(--blue0);
      border-bottom-color: var(--blue70);

      &:hover {
        border-bottom-color: var(--blue70);
      }
    }

    span {
      margin-left: 12px;
      margin-top: 5px;
    }
  }
}

.tabs-wrapper {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 44px;
    height: 44px;
    top: 6px;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }
}