.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 329px;
  width: 329px;
  padding: 16px;
  border: 1px solid var(--grey10);
  border-radius: 8px;
  background-color: var(--white);
  transition: background-color 0.2s;
  cursor: pointer;

  &:not(&_active):hover {
    background-color: var(--grey0);

    .link {
      opacity: 1;
    }
  }

  &_active {
    background-color: var(--blue0);

    .link {
      opacity: 1;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chips {
  text-transform: none !important;
  letter-spacing: 0.018em !important;
}

.link {
  display: flex;
  align-items: end;
  gap: 4px;
  font-size: 12px;
  color: var(--grey40);
  transition: color 0.2s, opacity 0.2s;
  cursor: pointer;
  opacity: 0;

  &:hover {
    color: var(--grey60);
  }
}

.financeBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_expired {
    opacity: 0.5;
  }
}

.balance,
.creditLimit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span:nth-child(1) {
    color: var(--grey100);
    font-size: 20px;
    font-weight: 600;
    line-height: 128.571%;
    text-transform: capitalize;
  }

  span:nth-child(2) {
    color: var(--grey40);
    font-size: 12px;
    line-height: 130.769%;
    letter-spacing: 0.16px;
  }
}

.creditLimit {
  align-items: flex-end;
}

.expirationDate {
  display: flex;
  flex-direction: column;
  gap: 6px;

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey40);
    font-size: 12px;

    > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.progressBar {
  display: flex;
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background-color: var(--grey10);

  &_expireSoon div {
    background-color: var(--orange60);
  }

  &_current div {
    background-color: var(--blue60);
  }

  &_currentInfinity div {
    background-color: var(--green60);
  }

  &_expired div {
    background-color: var(--red60);
  }

  div {
    position: absolute;
    inset: 0;
    transition: max-width 0.2s linear;
  }
}
