@import 'src/assets/extend';

.generalInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 24px 0 24px;
  z-index: 2;

  &_withBorder {
    border-bottom: 1px solid var(--grey5);
    padding: 24px;
  }
}

.tabs {
  height: 100%;

  > div[class*="bp3-tab-list"] {
    position: relative;
    margin: 14px 24px 0;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: var(--grey5);
      bottom: 0;
      left: -24px;
      right: -24px;
    }
  }

  > div[class*="bp3-tab-panel"] {
    margin-top: 0;
    height: 100%;
  }
}

.content {
  @extend %form__content;

  height: 100%;
  flex-direction: row;
  max-height: calc(100vh - 405px); //315
  position: relative;
  gap: 50px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 24px;
  min-width: calc(50% - 25px);
}

.subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 158%;
  letter-spacing: 0.004em;
  color: var(--grey100);

  &_required {
    &::before {
      content: '*';
      color: var(--red70);
    }
  }

  &_changed {
    color: var(--blue70);

    &::after {
      content: ' ●';
      color: var(--blue60);
    }
  }

  &_border {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid var(--grey5);
  }
}

.horDivider {
  background-color: var(--grey5);
  height: 1px;
  width: 100%;
  margin: 24px 0;
}

.callout {
  background-color: var(--grey5) !important;
  border-radius: 16px !important;
  white-space: break-spaces;
  line-height: 173%;

  > h4[class*="bp3-heading"] {
    background-color: var(--grey5) !important;
  }
}

.hint {
  color: var(--grey30);
  font-weight: 400;
  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.02em;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
  margin-top: 4px;
}

.colorIndicator {
  position: absolute;
  right: 8px;
  top: 10px;
  border-radius: 2px;
  width: 52px;
  height: 24px;
  cursor: pointer;
  border: 1px solid var(--grey20);
}

.divider {
  position: absolute;
  left: 50%;
  top: 0;
  width: 1px;
  background-color: var(--grey5);
}

.exampleImage {
  display: flex;
  flex: 1;
}
