.reports-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;

  &__toaster {
    margin-left: 20px;
  }
}
