.container{
  position: relative;
  overflow: hidden;
  background-color: var(--grey0);
}
.emptyIcon {
  background-color: var(--grey0);
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.withoutErrors {
  background-color: transparent;
}
.withErrors {
  .activity {
    display: none;
  }
}
.activityContainer {
  width: 100%;
  height: 100%;
}
.activity{
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(to left, rgba(241,241,241, .05), rgba(241,241,241, .3), rgba(241,241,241, .6), rgba(241,241,241, .3), rgba(241,241,241, .05));
  background-image: -moz-linear-gradient(to left, rgba(241,241,241, .05), rgba(241,241,241, .3), rgba(241,241,241, .6), rgba(241,241,241, .3), rgba(241,241,241, .05));
  background-image: -webkit-linear-gradient(to left, rgba(241,241,241, .05), rgba(241,241,241, .3), rgba(241,241,241, .6), rgba(241,241,241, .3), rgba(241,241,241, .05));
  animation: loading 1s infinite;
  z-index: 45;
}

@keyframes loading {
  0%{
    left: -45%;
  }
  100%{
    left: 100%;
  }
}
