@import "src/scss/variables";

.icons-cell-renderer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.5s;

    &:last-of-type {
      margin-left: 24px;
    }

    &:hover {
      opacity: 1;
    }

    @media (max-width: $screen-M) {
      height: 18px;
    }
  }
}
