.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--white);
  box-shadow: var(--shadow4dp);
  z-index: 11112;
  position: relative;

  &_collapsed {
    width: 73px;
  }

  &:hover {
    button[class*="toggleButton"] {
      display: flex;
    }
  }
}

.toggleButton {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 41px;
  right: -12px;
  box-sizing: border-box;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 2;
  background-color: var(--grey0);
  border: 1px solid var(--grey10);

  svg {
    transition: color 0.2s;
    color: var(--grey40);
  }

  &:hover svg {
    color: var(--grey70);
  }
}

.logo {
  padding: 12px;
  margin-bottom: 12px;

  > img {
    height: 32px;
    max-width: 100%;
    object-fit: contain;
  }
}
