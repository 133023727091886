.setting-field {
  margin: 0 16px 0 16px;

  &:first-child {
    margin-top: 7px;
  }

  &:last-child {
    margin-bottom: 7px;
  }

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  &__modal {
    display: none;
    &_active {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 320px);
      height: 100%;
      z-index: 999;
      border-radius: 8px 0 0 8px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: var(--indigo0);
    border-radius: 4px;
    color: var(--grey60);
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    &_expanded {
      border-radius: 4px 4px 0 0;
      color: var(--indigo100);
    }
  }

  &__header-controls {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    & > span {
      cursor: pointer;
    }

    & > span:first-child {
      margin-right: 9px;
    }
  }

  &__header-icon {
    margin-right: 8px;
    cursor: pointer;
  }

  &__body {
    border: 1px solid var(--grey0);
    display: flex;
    flex-direction: column;
  }

  &__field {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey0);
    }
  }

  &__code-modal {
    background: var(--white);
    width: 100%;
    padding: 16px 24px 32px;
    border-radius: 8px;
  }

  &__field-label {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 5px;
    border-right: 1px solid var(--grey0);
    font-weight: normal;
    font-size: 12px;
    line-height: 126%;
    letter-spacing: 0.033em;
    font-feature-settings: 'ss01'
  }

  &__field-control {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__text-area-field {
    textarea {
      padding-left: 0.5rem;
      padding-right: 0.375rem;
      margin-top: 0.0625rem;
      margin-bottom: 0.0625rem;
      width: 100%;
      border-radius: 0!important;
      box-shadow: none!important;
      font-weight: normal;
      font-size: 12px;
      line-height: 126%;
      letter-spacing: 0.033em;
      font-feature-settings: 'ss01';

      &:focus {
        box-shadow: 0 0 0 1px var(--color80)!important;
      }
    }
  }

  &__text-field, &__dropdown-field {
    &_with-error {
      box-shadow: 0 0 0 1px var(--red30);
    }
    &_with-error > input:focus {
      box-shadow: none !important;
    }
  }

  &__text-field {
    input {
      border-radius: 0!important;
      box-shadow: none!important;
    }
  }

  &__dropdown-field {
    input {
      border-radius: 0!important;
      box-shadow: none!important;
    }
  }

  &__switch-field > div {
    background: none!important;

    & > label > div:last-child > div {
      font-weight: normal;
      font-size: 12px;
      line-height: 126%;
      letter-spacing: 0.033em;
      font-feature-settings: 'ss01'
    }
  }

  &__code-field-input {
    border: 1px solid #B4B4BB;
    background-color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 200%;
    letter-spacing: 0.033em;

    .ace_gutter {
      background: var(--grey0);
    }
  }
}

.bp3-collapse-body {
  transform: none!important;
}
