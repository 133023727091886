@import "src/assets/extend";

.settings-block {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--grey0);
  width: 350px;
  min-width: 350px;

  &__header {
    display: flex;
    border-bottom: 1px solid var(--grey0);
    padding: 16px 19px;
    align-items: center;
    height: 56px;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-left: 8px;
  }

  &__body {
    display: flex;
    height: 0;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: scroll;
  }

  &__footer {
    border-bottom: 1px solid var(--grey0);
    padding: 16px 20px;
    display: flex;
  }

  &__top-shadow {
    width: 350px;
    @extend %form__top-shadow;
  }

  &__bottom-shadow {
    position: absolute;
    bottom: 68px;
    height: 16px;
    width: 350px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.07;
  }
}
