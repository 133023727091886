.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  overflow: hidden;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  s {
    text-decoration: line-through;
  }

  kbd {
    font-family: monospace;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: auto;
}

.card {
  display: contents;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}

div[class*="imageContainer"] {
  border-radius: 8px;
  background-color: var(--grey5);
  overflow: hidden;
}

.text,
.button {
  white-space: break-spaces;
  width: 100%;
  padding: 10px 12px;
  border-radius: 8px;
  line-height: 137%;
  letter-spacing: 0.16px;
  background-color: var(--grey5);

  &_header {
    font-size: 18px;
    font-weight: 500;
  }

  &_footer {
    font-size: 12px;
  }
}

.highlight {
  color: var(--blue70);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.divider {
  width: 100%;
  height: 2px;
  min-height: 2px;
  border-radius: 100px;
  background-color: var(--grey10);
  margin: 8px 0;
}

