@import "src/assets/extend";
@import "src/scss/variables";


.dropdown-field {
  display: flex;
  flex-direction: column;
  $field: &;

  &_disabled {
    cursor: not-allowed;
  }

  &_dense #{$field} {
    &__label {
      &_new-design {
        color: var(--grey30);
      }

      @extend %label_dense;
    }

    &__content {
      &__overlay {
        top: 31px;
        max-height: calc(28px * 5 + 8px);

        &__item {
          min-height: 28px;
          padding: 4px 6px;
          font-size: 12px;
        }
      }

      &__icon {
        right: 9px;
      }
    }
  }

  &__label {
    @extend %label;

    &_new-design {
      color: var(--grey30);
    }

    &_required {
      @extend %label_required;
    }
  }

  &__content {
    position: relative;

    &__input {
      position: relative;
      width: 100%;

      &_new-design > input {
        &:not(:hover) {
          box-shadow: 0 0 0 1px var(--grey5) !important;
        }
        &:hover {
          box-shadow: 0 0 0 1px var(--grey80) !important;
        }
        &:focus,
        &:active {
          box-shadow: 0 0 0 2px var(--grey80) !important;
        }
      }

      > input {
        padding-right: 1.825rem !important;
      }
    }

    &__icon{
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;

      &_open{
        path {
          fill: #4A4B57;
        }
      }
      &_disabled{
        cursor: not-allowed;
      }
    }

    &__overlay {
      @extend %field-overlay;
      position: unset;
      min-width: unset;
      max-height: calc(40px * 5 + 16px);

      &__item {
        @extend %field-overlay__item;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }

  &__left-element-wrapper {
    display: flex;
    height: 100%;
    padding: 0 12px;
    align-items: center;
  }

  .bp3-input-left-container {
    bottom: 0;
  }
}

.portal-content {
  z-index: 99999;
}