@import "src/assets/extend";

.link {
  text-decoration: underline;
  cursor: pointer;
}

.content {
  @extend %form__content;
  padding: 24px 0 0;
}

.templateInfo {
  border-bottom: 1px solid var(--grey5);
  padding: 0 24px 24px;
  margin-bottom: 24px;
}

.templateTopBlock {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.row {
  @extend %form__row;
  align-items: flex-end;
  margin-top: 24px;
}
