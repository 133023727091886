@import "../../../../assets/extend";

.service-form {
	@extend %form;

	&__shadow {
		@extend %form__top-shadow;
	}

	&__content {
		@extend %form__content;
		padding-bottom: 60px;
	}
}
