.screen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-image 0.3s ease-in-out;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 60px 105px;
  background-color: var(--white);
  border-radius: 14px;
  width: 624px;
}

.logo {
  max-width: 396px;
  max-height: 106px;
  object-fit: contain;
  margin: 0 auto;
}

.title {
  color: var(--grey80);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  margin-top: 60px;
}

.subtitle {
  color: var(--grey80);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  margin-top: 24px;
}

.infoRow {
  display: flex;
  gap: 4px;
  margin-top: 24px;

  span:first-of-type {
    margin-top: 2px;
  }
  span:last-of-type {
    color: var(--color70);
    line-height: 150%;
    letter-spacing: 0.44px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  button {
    width: 100%;
  }
}

.eyeButton {
  cursor: pointer;
  margin-top: 13px;
  margin-right: 10px;
  color: var(--grey30);
  transition: color 0.2s;
  user-select: none;

  &:hover {
    color: var(--grey50);
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textButton {
  cursor: pointer;
  color: var(--blue70);
  transition: color 0.2s;

  &:hover {
    color: var(--blue50);
  }
}

.twoFaHint {
  color: var(--grey40);
  text-align: center;
  line-height: 150%;
  letter-spacing: 0.44px;
}
