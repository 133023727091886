.application-modal-title {
	display: flex;
	align-items: center;
	padding: 0 24px;

	&__cell {
		display: flex;
		flex-direction: column;
		padding-right: 24px;
		margin: 10px 0;
		font-weight: 400;
		line-height: 150%;
		font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;

		&:first-of-type {
			margin-left: auto;
		}
		&:not(:last-of-type) {
			border-right: 1px solid var(--grey5);
			margin-right: 24px;
		}
	}

	&__title {
		margin-right: auto;
	}

	&__value {
		color: var(--grey100);
		letter-spacing: 0.0275em;
		font-size: 12px;
		line-height: 126%;
	}

	&__label {
		color: var(--grey40);
		font-size: 12px;
		line-height: 126%;
		margin-top: auto;
	}
}