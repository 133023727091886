.prefix {
    display: flex;
    flex: 1;
    height: 100%;

    &__layout {
        background: var(--blue0);
        padding: 16px 24px;
    }
}
