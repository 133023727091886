@import 'src/assets/extend';

.form {
  @extend %form;

  height: 100%;
}

.shadow {
  @extend %form__top-shadow;

  top: 31px;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 24px;
  z-index: 2;
  border-bottom: 1px solid var(--grey5);
}

.cell {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  margin: 10px 24px 10px 0;
  font-weight: 400;
  line-height: 150%;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
}

.label {
  color: var(--grey40);
  font-size: 12px;
  line-height: 126%;
  margin-top: auto;
}

.senderLink {
  color: var(--blue50);
  letter-spacing: 0.0275em;
  font-size: 12px;
  line-height: 126%;
  cursor: pointer;
}

.callout {
  background-color: var(--grey5) !important;
  border-radius: 16px !important;
  white-space: break-spaces;
  line-height: 173%;

  > h4[class*="bp3-heading"] {
    background-color: var(--grey5) !important;
  }
}

.calloutWrapper {
  padding: 0 24px 24px;
}
