@import "../../../assets/extend";

.form {
  @extend %form;
}

.title {
  color: var(--grey100);
  font-family: var(--inter), sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 158%;
  letter-spacing: 0.08px;
  margin-bottom: 14px;

  &:not(:first-child) {
    margin-top: 24px;
  }
}

.shadow {
  @extend %form__top-shadow;
}

.content {
  @extend %form__content;
  padding: 24px;
}

.row {
  @extend %form__row;
  gap: 24px;
}

.modal {
  min-width: 860px;
  max-width: 1150px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  > span {
    color: var(--grey70);
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: var(--grey100);
    }
  }
}

.modalTitle {
  font-size: 18px;
  font-weight: normal;

  > b {
    font-weight: bold;
    margin-left: 4px;
  }
}
