@import '../../../assets/extend';

.sender-form {
	@extend %form;

	height: 100%;

	&__shadow {
		@extend %form__top-shadow;
	}

	&__content {
		@extend %form__content;

		flex-direction: row;
		height: 100%;
	}

	&__column {
		display: flex;
		flex-direction: column;
		flex: 1;

		&:first-of-type {
			margin-right: 12px;
		}

		&:last-of-type {
			margin-left: 12px;
		}
	}
}