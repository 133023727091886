@import "src/assets/extend";

.multiselect-field {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: baseline;

  &__label {
    @extend %label;

    &_required {
      @extend %label_required;
    }
  }

  &__input {
    display: flex;
    padding: 8px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px var(--grey30);
    border: none;
    color: var(--grey100);
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    min-height: 2.5rem;
    border-radius: 0.25rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;

    &_disabled {
      background-color: var(--color0);
      color: var(--grey100);
      box-shadow: 0 0 0 1px var(--color30) !important;
      cursor: not-allowed;
    }

    &_error {
      box-shadow: 0 0 0 1px var(--red30);
    }

    &:hover {
      color: var(--grey100);
      box-shadow: 0 0 0 1px var(--grey80);
      transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &_focused, &_focused:hover {
      box-shadow: 0 0 0 2px var(--grey80);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  &__items {
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    margin: 2px;
    user-select: none;

    &_last > .bp3-tag {
      background-color: var(--color20);
    }
  }

  &__container-input {
    flex: 1 1 auto;
    margin: 2px;
    height: 20px;
    border: 0!important;
    box-shadow: none!important;
    background-color: transparent;
  }

  &__overlay {
    display: flex;
    position: absolute;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(58, 58, 68, 0.2), 0 4px 8px rgba(90, 91, 106, 0.2);
    border-radius: 4px;
    max-height: 215px;
    z-index: 100;
    top: 100%;
    width: 100%;
    max-width: 100%;
  }

  &__add-button {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    padding: 8px;
    color: var(--blue60);
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.0275em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
    border-radius: 0 0 4px 4px;

    &_selected {
      background-color: var(--blue60);
      color: var(--white);
    }
  }

  &__overlay-helper {
    padding: 8px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 126%;
    letter-spacing: 0.02em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
    color: var(--grey60);
    background-color: var(--grey0);
    border-radius: 0 0 4px 4px;
  }

  &__overlay-content {
    overflow-y: scroll;
    scrollbar-visibility: hidden;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__overlay-item {
    padding: 4px 8px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.0275em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child) {
      margin-top: 2px;
    }

    &:not(:last-child) {
      margin-top: 2px;
    }

    &:hover {
      background-color: var(--blue60);
      color: var(--blue0);
    }
  }
  &__error-helper-text {
    color: var(--red50);
    position: absolute;
    z-index: 1;
    font-size: 10px;
    letter-spacing: var(--letter_spacing_caption);
    font-weight: var(--font_weight_caption);
    animation: shake-horizontal 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    top: 100%;
    line-height: 120%;
  }
}
