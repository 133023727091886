@import "../../../../assets/extend";

.setup-fee {
	@extend %form;
	padding: 0 24px;

	&__subtitle {
		font-weight: 500;
		font-size: 20px;
		line-height: 158%;
		letter-spacing: 0.004em;
		color: var(--grey100);
		margin-bottom: 14px;
	}

	&__row {
		display: flex;
	}
}
