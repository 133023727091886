.ag-column-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .ag-header-cell-label {
    flex-direction: row;

    &_button {
      cursor: pointer;
    }
  }

  &__filter-point {
    position: absolute;
    height: 100%;
  }

  svg {
    cursor: pointer;
  }

  &__filter {
    display: flex;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
    width: 14px;
    height: 16px;
    min-width: 14px;
    opacity: 0;
    color: var(--blue70);

    &:hover {
      opacity: 1;
    }
  }

  &__reset-filter {
    display: none;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    color: var(--red70);

    &:hover {
      opacity: 1;
    }
  }
}

.ag-header-cell {

  &:not(:last-of-type) {
    border-right: 1px solid var(--indigo5) !important;
  }

  .ag-header-cell-text {
    line-height: 143%;
    letter-spacing: 0.018em;
    color: var(--grey50);
  }

  &.ag-column-menu-visible {
    background-color: #f7f7fa;
  }

  // когда меню открыто, иконка фильтра не прозрачная
  &.ag-column-menu-visible .ag-column-header__filter {
    opacity: 1;
  }

  // когда фильтр применен, иконка фильтра меняется и становится прозрачной на 0.6
  &.ag-header-cell-filtered .ag-column-header__filter {
    opacity: 0.6;
  }

  &:hover .ag-column-header__filter {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  &.ag-header-cell-filtered .ag-column-header__reset-filter {
    display: flex;
  }
}

.ag-header-sort-indicator {
  display: none;
  width: 10px;
  min-width: 10px;
  height: 10px;
  background-size: 100% 100%;
  margin-left: 10px;

  &_asc,
  &_desc {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_desc {
    transform: rotate(180deg);
  }
}
