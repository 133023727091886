@import "src/assets/keyframes";

.pagination {
  display: flex;
  align-items: center;
  $pagination: &;

  &_dense #{$pagination} {
    &__wrapper__text,
    &__rows__title,
    &__rows__count {
      font-size: 12px;
      line-height: 126%;
      letter-spacing: 0.033em;
    }

    &__wrapper__input,
    &__rows__dropdown {
      font-size: 12px;
    }

    &__rows {
      &__container {
        left: 36px;
        bottom: 30px;
      }

      &__dropdown {
        height: unset;

        &::after {
          top: 10px;
        }
      }
    }

    &__wrapper {
      margin: 0 12px;
    }

    &__icon {
      &:first-of-type,
      &:last-of-type {
        margin: 0;
      }
    }

    &__refresh {
      margin-left: 24px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin: 0 22px;

    &__text {
      font-family: var(--inter);
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.018em;
      color: var(--grey60);

      span {
        color: var(--grey100);
        margin-left: 4px;
      }
    }

    &__input {
      max-width: 32px;
      background: var(--white);
      border-radius: 4px;
      padding: 4px 8px;
      margin: 0 4px;
      box-shadow: 0 0 0 1px var(--color30);
      border: none;
      transition: box-shadow 0.1s ease-in-out;

      &:hover {
        box-shadow: 0 0 0 1px var(--color80);
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0 2px var(--color80);
      }
    }
  }

  &__icon {
    cursor: pointer;
    
    &:first-of-type {
      margin-right: 12px;
    }

    &:last-of-type {
      margin-left: 12px;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &__refresh {
    cursor: pointer;
    margin-left: 36px;

    &_spin {
      cursor: not-allowed;
      animation: 1s spin infinite linear;
    }
  }

  &__rows {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 16px;

    &__title {
      font-family: var(--inter);
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.018em;
      margin-right: 4px;
      color: var(--grey60);
    }

    &__page-info {
      margin: 0 4px;
    }

    &__dropdown {
      width: 54px;
      text-align: left;
      position: relative;

      &::after {
        border-top-color: var(--grey100);
        position: absolute;
        right: 9px;
        top: 12px;
      }
    }

    &__container {
      bottom: 35px;
      left: 42px;
      min-width: 58px;
      overflow-y: scroll;
      position: absolute;
      border-radius: 4px;
      background-color: #fff;
      z-index: 100;
      padding: 6px 0 6px 6px;
      box-shadow: 0 4px 8px rgba(58, 58, 68, 0.16), 0 8px 16px rgba(90, 91, 106, 0.16);

      &__item {
        border: none;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        font-family: var(--inter);
        cursor: pointer;
        background-color: #fff;
        transition: background-color 0.2s ease-in-out;
        text-align: center;
        justify-content: center;
        color: var(--grey100);

        &:hover {
          background-color: var(--blue5);
        }
      }
    }

    &__count {
      font-family: var(--inter);
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.018em;
      color: var(--grey60);
      margin-left: 4px;

      span {
        margin-left: 4px;
        color: var(--grey100);
      }
    }
  }
}
