@import "../../../../assets/extend";

.container {
  @extend %form;
}

.shadow {
  @extend %form__top-shadow;
}

.content {
  @extend %form__content;

  gap: 16px;
}

.uppercaseField input {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}
