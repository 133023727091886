.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.imageContainer {
  position: relative;

  &:hover {
    .downloadButton {
      display: flex;
    }
  }
}
.downloadButton {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.media {
  position: relative;
  border-radius: 4px;
  height: 80px;
  width: 80px;
  cursor: auto;
}
.image {
  width: 362px;
  height: 198px;
  cursor: auto;
  position: relative;
  border-radius: 4px;
}
.video {
  border-radius: 4px;
}
.fileIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 362px;
  height: 198px;
  border-radius: 4px;
  background-color: var(--grey0);
}
