.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.fieldsContainer {
  display: flex;
  padding: 0 10px 0 12px;
  align-items: center;
  gap: 14px;
  height: 40px;
  align-self: stretch;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px var(--grey20);
  background: #FFFFFF;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  width: 100%;

  &:hover {
    background: #FCFCFD;
  }
}

.isOpen {
  box-shadow: 0 0 0 2px var(--blue40);
  background: #FFFFFF;
}

.field, .mode {
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-family:var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.field {
  color: var(--grey100);
  white-space: nowrap;
  width: 100%;

  &:empty::before {
    content: 'DD.MM.YYYY';
    color: var(--grey50);
  }
}

.mode {
  white-space: nowrap;
  color: var(--grey50);
}
