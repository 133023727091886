.scope-item {
	display: flex;
	flex-direction: column;
	border: 1px solid var(--grey5);
	cursor: pointer;
	position: relative;
	$item: &;

	&_new-scope {
		opacity: 0;
		position: absolute;
		z-index: -1;

		#{$item}__read-only-info {
			background-color: var(--white);
		}
	}

	&_new-scope.scope-item_active {
		opacity: 1;
		z-index: 1;
		position: relative;
	}

	&_active {
		border-color: var(--blue40);

		#{$item}__controls-wrapper {
			opacity: 1;
		}
		#{$item}__read-only-info {
			background-color: var(--blue0);
		}
	}

	&_expand #{$item}__body {
		max-height: 10000px;
		transition: max-height 1s ease-in-out;
	}

	&__plus-button {
		display: flex;
		align-items: center;
		color: var(--blue70);
		gap: 8px;
		height: 40px;
		width: 100%;
	}

	&__read-only-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--grey0);
		padding: 10px 12px;
		border-radius: inherit;
	}

	&__column {
		display: flex;
		flex-direction: column;
	}

	&__controls-wrapper {
		display: flex;
		gap: 8px;
		margin-left: 16px;
		opacity: 0;
		transition: opacity 0.2s;

		button {
			min-width: 28px;
		}
	}

	&__range {
		display: flex;
		margin-top: 8px;
		font-size: 12px;
	}

	&__body {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
		max-height: 0;
	}

	&__form-wrapper {
		display: flex;
		flex-direction: column;
		padding: 16px;
		gap: 16px;
	}

	&__form-buttons {
		display: flex;
		justify-content: flex-end;
		gap: 16px;
		margin-top: 12px;
		padding-top: 12px;
		border-top: 1px solid var(--grey5);

		button {
			min-width: 28px;
		}
	}
}