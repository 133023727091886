.container{
  width: 772px;
  background-color: #FFFFFF;
}

.calendars {
  gap: 32px;
  display: flex;
  flex-direction: row;
  padding: 0 14px;

  // Панель выбора
  div[class*="rc-picker-panel"] {
    width: 340px;
    border: 0;
    background-color: #FFFFFF;

    &:first-of-type {
      margin-left: 8px;
    }

    &:last-of-type {
      margin-right: 8px;
    }
  }

  // Панели для выбора дат, месяцев, годов и т.д.
  div[class*="rc-picker-year-panel"],
  div[class*="rc-picker-date-panel"],
  div[class*="rc-picker-decade-panel"],
  div[class*="rc-picker-month-panel"],
  div[class*="rc-picker-quarter-panel"],
  div[class*="rc-picker-week-panel"] {
    table {
      width: 100%;
      border-collapse: collapse;
    }
  }

  // Панель месяца, года и десятилетий
  div[class*="rc-picker-month-panel"],
  div[class*="rc-picker-year-panel"],
  div[class*="rc-picker-decade-panel"] {
    td {
      height: 84px;
      width: 44px;
      background-clip: content-box;
      padding-top: 42px;
    }

    tr:first-of-type td {
      padding-top: 0;
      height: 42px;
    }
  }


  div[class="rc-picker-header"] {
    height: 38px;
    margin: 14px 0;
    display: flex;
    align-content: center;
    > button {
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  /* Заголовок */
  div[class*="rc-picker-header-view"] {
    display: flex;
    justify-content: center;
    gap: 5px;

    & > button {
      background-color: transparent;
      color: var(--grey100);
      text-align: center;
      font-feature-settings: 'ss01' on;
      font-family: var(--inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.352px;
      cursor: pointer;
    }
  }

  /* Общие стили */
  th {
    height: 24px;
    color: var(--grey40);
    font-family: var(--inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  td {
    height: 44px;
    width: 44px;
    background-clip: content-box;
    padding: 2px 0;

    &:not(&:last-child) {
      margin-right: 4px;
    }
  }

  td[class*="rc-picker-cell"] {
    color: var(--grey40);
  }

  td[class*="rc-picker-cell-today"] > div {
    border: 0;
  }

  td[class*="rc-picker-cell-range-start"] {
    border-radius: 8px 0 0 8px;
    > div {
      border-radius: 8px 0 0 8px;
      border: 2px solid var(--blue60);
      background-color: var(--blue0);
    }
  }
  td[class*="rc-picker-cell-in-view"] {
    color: var(--grey100) !important;
  }

  td[class*="rc-picker-cell-in-range"] {
    background-color: var(--blue0);
  }

  td[class*="rc-picker-cell-range-end"] {
    border-radius: 0 8px 8px 0;
    > div {
      border-radius: 0 8px 8px 0;
      border: 2px solid var(--blue60);
      background-color: var(--blue0);
    }
  }

  td[class*="rc-picker-cell-range-start rc-picker-cell-range-end"] > div {
    border-radius: 8px;
  }

  // Внутренности ячеек
  div[class*="rc-picker-cell-inner"] {
    height: 100% !important;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
  }

  div[class*="rc-picker-cell-inner"] {
    font-family: var(--inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  // Панель недель
  div[class*="rc-picker-week-panel"] td[class*="rc-picker-cell"] {
    background-color: transparent;
  }

  tr[class*="rc-picker-week-panel-row-range-start"],
  tr[class*="rc-picker-week-panel-row-range-end"] {
    background-color: transparent;

    & > td:nth-child(2) > div {
      border-radius: 8px 0 0 8px;
      border-top: 2px solid var(--blue60);
      border-bottom: 2px solid var(--blue60);
      border-left: 2px solid var(--blue60);
      background-color: var(--blue0);
    }

    & > td:last-of-type > div {
      border-radius: 0 8px 8px 0;
      border-top: 2px solid var(--blue60);
      border-right: 2px solid var(--blue60);
      border-bottom: 2px solid var(--blue60);
      background-color: var(--blue0);
    }

    & > td:not(:first-child):not(:last-of-type):not(:nth-child(2)) > div {
      border-right: 0;
      border-left: 0;
      border-top: 2px solid var(--blue60);
      border-bottom: 2px solid var(--blue60);
      border-radius: 0;
      background-color: var(--blue0);
    }
  }

  tr[class*="rc-picker-week-panel-row-range-hover"] {
    & > td:nth-child(2) > div {
      background-color: var(--blue0);
    }

    & > td:last-of-type > div {
      background-color: var(--blue0);
    }

    & > td:not(:first-child) > div {
      background-color: var(--blue0);
    }
  }

  div[class*="rc-picker-week-panel-row"]:hover div {
    background-color: transparent;
  }

}
