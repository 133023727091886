.container {
	display: flex;
	flex-direction: column;
	border-top: 1px solid var(--grey5);

	div[class*="view_flat"] {
		background-color: transparent;
	}

	&_locked {
		background-color: var(--grey0);
	}

	&_open {
		.chevron {
			transform: scale(1, -1);
		}

		.dependenciesList {
			max-height: 10000px;
			transition: max-height 1s ease-in-out;
		}
	}
}

.row {
	display: flex;
	align-items: center;
	padding: 17px 24px;
	transition: opacity 0.2s;

	&_disabled {
		opacity: 0.5;
	}
}

.chevron {
	margin-left: auto;
	cursor: pointer;
	transition: transform 0.2s linear;
}

.dependenciesList {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
	max-height: 0;
}

.dependency {
	display: flex;
	align-items: center;
	padding: 7px 24px 7px 84px;
	border-top: 1px solid var(--grey5);
	color: var(--grey40);
	letter-spacing: 0.0275em;
	font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
	font-size: 16px;
	line-height: 150%;
}
