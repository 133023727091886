.accordion {
  flex: 1;

  &__checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:checked {
      ~ .accordion__item > .accordion__item__right > .accordion__item__chevron  {
        color: var(--grey70);
      }
      ~ .accordion__item__content {
        max-height: 100vh;
        transition: max-height 1s ease-in-out;
        border-top: 1px solid var(--grey5);
      }
    }
  }

  &__item-container {
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey5);
    }
  }

  &__sub-item {
    display: flex;
    flex-direction: row;
    height: 54px;
    align-items: center;
    padding: 0 41px;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey5);
    }

    &__icon {
      height: 30px;
      width: 30px;
      cursor: pointer;

      &:hover > * {
        fill: red;
      }
    }

    &__title {
      margin-left: 8px;
    }
  }

  &__item {
    display: flex;
    height: 64px;
    align-items: center;
    padding: 0 14px;
    cursor: pointer;
    justify-content: space-between;

    &__date {
      color: var(--grey50);
    }

    &__chevron {
      color: transparent;
      margin-left: 31px;
    }

    &__left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__icon {
      &:hover > * {
        fill: red;
      }
    }

    &__content {
      background: #F7F7FA;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }

    &__title {
      margin-left: 12px;
    }
  }
}
