.uploadModalWindow {
  border: 1px dashed var(--blue50);
  overflow: hidden;
  padding: 0;
  background-color: #fff;
}

.uploadArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 24px;

  input {
    display: none;
  }
}

.uploadHint {
  margin-top: 24px;
  font-weight: bold;

  span {
    color: var(--blue70);
    text-decoration: underline;
    margin-left: 4px;
    cursor: pointer;;
  }
}

.acceptFormats {
  margin-top: 14px;
}

.backdrop {
  background-color: rgba(25, 25, 29, 0.4);
}
