@import "src/assets/extend";

.text-area-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &__counter {
    position: absolute;
    bottom: -1.33rem;
    right: 0;
    color: var(--grey50);
    letter-spacing: 0.02em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
    font-size: var(--font_size_caption_small);
    font-weight: var(--font_weight_caption_small);
  }

  &__label {
    @extend %label;

    &_new-design {
      color: var(--grey30);
    }

    &_required {
      @extend %label_required;
    }
  }

  &__copy-button {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
  }

  &__error-helper-text {
    color: var(--red50);
    position: absolute;
    z-index: 1;
    font-size: 10px;
    letter-spacing: var(--letter_spacing_caption);
    font-weight: var(--font_weight_caption);
    animation: shake-horizontal 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    bottom: -1.33rem;
  }

  &__input {
    resize: none;
    outline: none;
    border: none;
    box-shadow: 0 0 0 1px var(--grey30);
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    line-height: 146%;
    border-radius: 4px;
    padding: 8px 12px;
    transition: box-shadow 0.2s ease-in-out;
    min-height: 40px;

    &_error {
      box-shadow: 0 0 0 1px var(--red30);
      border: 0 !important;
    }

    &::placeholder {
      color: var(--grey40);
    }

    &_new-design {
      font-size: 14px;

      &:not(:hover) {
        box-shadow: 0 0 0 1px var(--grey5) !important;
      }
      &:hover {
        box-shadow: 0 0 0 1px var(--grey80) !important;
      }
      &:focus,
      &:active {
        box-shadow: 0 0 0 2px var(--grey80) !important;
      }
    }

    &:disabled {
      background-color: var(--color0) !important;
      box-shadow: 0 0 0 1px var(--color30) !important;
      color: initial;
      cursor: not-allowed;
    }

    &:hover {
      box-shadow: 0 0 0 1px var(--grey80);
    }

    &:focus,
    &:active {
      box-shadow: 0 0 0 2px var(--grey80);
    }
  }
}
