@import "src/assets/extend";

.reports-table-layout {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;

  &__file-dropdown {
    @extend %field-overlay;
    top: -77px;
    overflow: auto;
  }

  &__dropdown-item {
    border: none;
    box-sizing: border-box;
    width: 100%;
    min-height: 28px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    border-radius: 4px;
    font-family: var(--inter);
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
    text-align: left;
    justify-content: flex-start;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.018em;

    &:hover {
      background-color: #EBF2FF;
    }
  }

  &__download-container {
    position: relative;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid var(--grey40);
  }
}
