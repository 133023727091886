#root {
  display: flex;
  min-height: 100vh;
  // overflow-x: hidden;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9D9D9D transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #9D9D9D;
  border-radius: 100px;
}

.screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  &__container {
    height: 100%;
    max-height: calc(100vh - 56px);
    padding: 16px 24px;
    background-color: var(--grey0);

    &_padding0 {
      padding: 0;
    }

    > div {
      display: flex;
      height: 100%;
    }
  }
}

.ag-header-cell:first-child {
  .ag-column-header__filter-point {
    left: 160px;
  }
}
