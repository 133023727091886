.popover-edit-block {

	&__overlay {
		display: flex;
		flex-direction: column;
		background-color: var(--white);
		box-shadow: var(--shadow16dp);
		padding: 24px;
		border-radius: 8px;
		width: 256px;
	}

	&__title {
		color: var(--black);
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		letter-spacing: 0.0075em;
		margin-bottom: 14px;
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
		gap: 16px;
	}
}
