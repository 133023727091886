@import "src/assets/extend";

.date-filter {
  @extend %custom-filter;

  &__header {
    @extend %custom-filter__header;
  }

  &__button-group {
    @extend %custom-filter__button-group;
  }

  &__top-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__card {
    &__content {
      @extend %custom-filter__card__content;

      &__text {
        @extend %custom-filter__card__content__text;
      }
    }
  }

  &__filter-controls {
    @extend %custom-filter__filter-controls;
    &__button {
      @extend %custom-filter__filter-controls__button;

      &__text {
        @extend %custom-filter__filter-controls__button__text;
      }
    }
  }

  &__from, &__to {
    .bp3-popover2-transition-container{
      top: 0 !important;
    }
  }
  
  &__from{
    .bp3-popover2-arrow{
      top: 128px !important;
    }
  }
  
  &__to{
    .bp3-popover2-arrow{
      top: 235px !important;
    }
    &_small{
      .bp3-popover2-arrow{
        top: 187px !important;
      }
    }
  }
}