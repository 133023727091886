.modal {
  background-color: #fff;
  width: 90%;
  height: calc(100vh - 120px);
  padding: 32px 24px;
}

.backdrop {
  background-color: rgba(25, 25, 29, 0.4);
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 17px;
  line-height: 126%;
  color: var(--grey40);
}

.closeModalButton {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  color: var(--grey50);

  &:hover {
    color: var(--color70);
  }
}

.content {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  flex: 1;
}
