@import "src/scss/variables";

.direction-cell-renderer {
  &_inbound {
    background-color: var(--indigo5) !important;
    color: var(--indigo70) !important;
  }

  &_outbound {
    background-color: var(--teal5) !important;
    color: var(--teal70) !important;
  }
}
