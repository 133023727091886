.drawer {
	border-radius: 0;
	overflow-y: hidden;
	padding-bottom: 24px;
	margin-top: 50px;
	padding-top: 24px;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;

		&__title {
			font-family: var(--inter);
			color: var(--grey100);
			font-weight: 500;
			font-size: 20px;
			line-height: 158%;
			letter-spacing: 0.004em;
		}
	}

	&__close-button {
		background: none !important;
		box-shadow: none !important;
		border: none;
		padding: 0 !important;
	}
}