.charge-price {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-top: 24px;
	color: var(--green60);

	&_error {
		color: var(--red60);
	}

	&__service-name {
		color: var(--blue60);
		margin-left: 4px;

		&:not(:empty) {
			&::after {
				content: ')';
			}
			&::before {
				content: '(';
			}
		}
	}

	&__effective-date {
		color: var(--grey40);
		font-size: 14px;
	}
}
