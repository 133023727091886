.channels-container-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 4px;
}
.channel-icon {
  height: 26px;
  width: 26px;
}
