@import "src/assets/extend";

.list-filter {
  @extend %custom-filter;

  &__header {
    @extend %custom-filter__header;
  }

  &__button-group {
    @extend %custom-filter__button-group;
  }

  &__card {
    margin-bottom: 32px;
    &__content {
      @extend %custom-filter__card__content;

      &__text {
        @extend %custom-filter__card__content__text;
      }
    }
  }

  &__list {
    margin-top: 16px;
    max-height: 150px;
    overflow: scroll;
    margin-bottom: 6px;

    &__item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      align-items: center;

      &__chips {
        margin-left: 3px;
      }

      &__text {
        font-size: var(--font_size_caption);
        margin-left: 3px;
      }
    }
  }

  &__filter-controls {
    margin-top: 0;
    @extend %custom-filter__filter-controls;
    &__button {
      @extend %custom-filter__filter-controls__button;

      &__text {
        @extend %custom-filter__filter-controls__button__text;
      }
    }
  }
}
