.form-buttons-group {
  display: flex;
  flex-direction: column;
  padding: 24px;

  &_shadow {
    z-index: 18;
    box-shadow: 0 0 16px rgba(90, 91, 106, 0.16);
  }

  &__buttons {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  &__right-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 24px;
  }
}
