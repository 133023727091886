.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: var(--shadow8dp);
  width: 968px;
  overflow: hidden;
}

.small {
  width: 772px;
}

.footer {
  height: 54px;
  display: flex;
  border-top: 1px solid var(--grey5);
  padding: 14px 22px;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  > button {
    align-self: initial;
  }
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.grouping {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 22px;
  width: 196px;
  border-right: 1px solid var(--grey5);
}

.calendar {
  display: flex;
  flex: 1;
}

.title {
  color: var(--grey100);
  font-feature-settings: 'ss01' on;
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.352px;
}

.list {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.listItem {
  padding: 6px 16px;
  border-radius: 4px;
  color: var(--grey70);
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  cursor: pointer;

  &:hover {
    background: var(--grey0);
  }
}

.selected {
  background: var(--grey5);

  &:hover {
    background: var(--grey5);
  }
}

.disabled {
  cursor: not-allowed;
  color: var(--grey30);
}
