.drawerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  line-height: 126%;
  font-weight: normal;
  gap: 16px;
  color: var(--grey30);

  > span svg {
    cursor: pointer;
    transition: fill 0.2s;

    &:hover {
      fill: var(--grey70);
    }
  }
}

.drawerBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-top: 24px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 24px;
  padding-bottom: 2px;
  gap: 24px;
}
