@import 'src/assets/extend';

.generalInfo {
  display: flex;
  gap: 24px;
  padding: 0 24px;
  margin-bottom: 24px;
}

.content {
  @extend %form__content;
  flex-direction: row;
  height: 100% ;
  padding-top: 0;
  overflow-y: auto;
  gap: 25px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 24px;
  min-width: calc(50% - 25px);
}

.subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 158%;
  letter-spacing: 0.004em;
  color: var(--grey100);
  margin-top: 24px;
  margin-bottom: 14px;
}

.label {
  @extend %label
}

.documents {
  display: grid;
  width: 100%;
  height: 100%;
  grid-auto-columns: auto;
  overflow-y: auto;
  gap: 15px;
  padding-top: 14px;
  justify-content: start;
  grid-template-columns: repeat(auto-fill, minmax(100px, 100px));
  grid-auto-rows: 100px;
}

.file {
  position: relative;
  &:hover {
    .fileDownload {
      display: flex;
    }
    .fileInfoWrapper {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      opacity: 1;
    }
  }
}

.fileIcon {
  width: 100%;
  height: 100%;
}

.fileDownload {
  display: none;
  position: absolute;
  top: -14px;
  right: -10px;
  width: 28px;
  height: 28px;
  border: 1px solid var(--grey30);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
}

.fileInfoWrapper {
  background-size: 100% 100%;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  transition: opacity .3s ease-in-out;
  width: 100%;
  z-index: 2;
}

.fileInfoText {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #FFFFFF;
}
