.content {
  margin-bottom: 20px;
  padding: 0 24px 0;
}

.headerType {
  display: flex;
  margin-bottom: 10px;
  gap: 5px;
}

.buttonBlock {
  display: flex;
  gap: 20px;
}

.offerText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.carouselItems {
  display: flex;
  gap: 14px;
  margin: 20px 0;
}

.carouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 8px;
  background: var(--grey0);
  cursor: pointer;
}

.selectedItem {
  background: var(--blue0);
  border: 1px solid var(--blue40);
  > span[class*="bp3-icon"] svg {
    fill: var(--blue40) !important;
  }
}
