@import "../../../../assets/extend";

.container {
	@extend %form;
}

.shadow {
	@extend %form__top-shadow;
}

.content {
	@extend %form__content;
	padding-bottom: 44px;
	gap: 24px;
}

.row {
	display: flex;
	align-items: center;
	gap: 24px;
}

.callout {
	@extend %callout-overrider;

	> div[class*="bp3-spinner"] {
		position: absolute;
		left: 1.125rem;
		top: 0.55rem;
	}
}
