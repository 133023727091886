.create-report-block {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 700px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__body {
    display: flex;
    margin-top: 16px;
    flex: 1;
    flex-direction: row;
    border-radius: 8px;
    background-color: var(--white);
    position: relative;
  }

  &__form-container {
    display: flex;
    flex: 3;
    flex-direction: column;
    padding: 18px 16px;
  }

  &__form {
    padding: 24px 0;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 158%;
    letter-spacing: 0.004em;
  }

  &__code-field {
    width: 100%;
  }

  &__code-field-input {
    border: 1px solid #B4B4BB;
    background-color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 200%;
    letter-spacing: 0.033em;

    .ace_gutter {
      background: var(--grey0);
    }
  }

  &__dropdown-field {
    margin-left: 18px;
    @media (max-width: 1200px) {
      margin-left: 0;
      margin-top: 24px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__footer {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    &_cancel {
      background-color: var(--white)!important;
    }
  }

  &__button-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    color: var(--green0);
    letter-spacing: 0.022em;
    text-transform: none;

    &_delete {
      color: var(--red40);
    }

    &__disabled {
      color: var(--green40);
    }

    &_outlined{
      color: var(--blue70);
    }
  }
}
