.scrollable-tabs {
	display: flex;
	flex-direction: column;
	$tabs: &;

	&__tabs-panel-wrapper {
		display: flex;
		align-items: center;
		gap: 24px;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 18px;

		&:last-child {
			margin-left: auto;
		}
	}

	&__tabs {
		display: flex;
	}

	&__tabs-list-item {
		display: flex;
		position: relative;
		align-items: center;
		white-space: nowrap;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		font-family: var(--inter);
		text-transform: initial;
		color: var(--grey100);
		letter-spacing: 0.0275em;
		font-feature-settings: 'ss01' on;

		&:hover #{$tabs}__close-tab-button {
			opacity: 0.7;
		}
		&:has(span) {
			padding: 0 8px;
		}
	}

	&__tabs-list-item-text {
		max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__close-tab-button {
		margin-left: 6px;
		position: absolute;
		right: -6px;
		opacity: 0;
		transition: opacity 0.2s;

		&:hover {
			opacity: 1 !important;
		}
	}

	div[role="tabpanel"] {
		height: 100%;
		overflow: hidden;
	}

	.MuiTabs-root {
		min-height: 28px;
	}

	.MuiButtonBase-root.Mui-selected > div {
		color: var(--blue70);
	}

	.MuiButtonBase-root {
		padding: 3px 8px;
		min-height: unset;
	}

	.MuiTabs-indicator {
		height: 2px;
		transition: none;
		border-radius: 3px;
	}

	.MuiTouchRipple-root {
		display: none;
	}

	.MuiTabScrollButton-horizontal {
		min-width: unset;
		width: unset;
		padding: 0;
	}
}
