@import "src/assets/extend";

%shadow {
  content: "";
  height: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.form-content-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;

  &_top-shadow:before {
    @extend %shadow;

    box-shadow: 0 4px 8px rgba(58, 58, 68, 0.08), 0 8px 16px rgba(90, 91, 106, 0.12);
    top: -20px;
    bottom: auto;
  }

  &_bottom-shadow:after {
    @extend %shadow;

    box-shadow: 0 0 16px rgba(90, 91, 106, 0.16);
    top: auto;
    bottom: -20px;
  }

  &__content {
    @extend %form__content;
  }
}
