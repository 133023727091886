.record-info {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.033em;
  color: var(--blue100);
  background: var(--blue5);
  border-radius: 4px;
  padding: 4px 8px;
  position: relative;
  cursor: pointer;
  outline: none;
  border: none;

  &__label {
    color: var(--blue100);
    margin-right: 4px;
    margin-left: 10px;
  }

  &__value {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.018em;
    line-height: 143%;
    color: var(--grey40);
    font-family: var(--inter);

    span {
      margin-left: 4px;
      color: var(--grey100);
    }
  }

  &__copy {
    border-radius: 4px;
    display: block;
    margin-left: auto;
    margin-top: 28px;
    font-weight: normal;
  }

  &__text {
    font-family: var(--inter);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.018em;
    color: #2264d1 !important;
  }

  &__overlay {
    position: absolute;
    background: #ffffff;
    box-shadow: 0 12px 24px rgba(27, 78, 163, 0.08),
    0 24px 48px rgba(41, 121, 255, 0.08);
    border-radius: 4px;
    padding: 16px;
    z-index: 2;
    top: 30px;
    right: 0;
    transform: translateX(25%);
    min-width: 290px;
  }
}
