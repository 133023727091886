.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid var(--grey10);
  overflow: hidden;
}

.searchRow {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-bottom: 1px solid var(--grey10);
  gap: 8px;

  > input {
    border: none;
    font-size: 16px;
    width: 100%;
    padding: 0;
  }

  > svg {
    width: 16px;
    height: 16px;
    fill: var(--grey50);
  }
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 12px;
  padding: 16px 8px;
}

.changesInfo {
  color: var(--color70);
  margin-top: 8px;
  font-weight: normal;

  &:first-of-type {
    margin-top: 16px;
  }
}
