.details-block {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	background-color: var(--white);

	&__instance-info {
		display: flex;
		gap: 14px;
		border-bottom: 1px solid var(--grey10);
		padding: 14px;
	}

	&__separated-row {
		display: flex;
		justify-content: space-between;
		background-color: var(--grey0);
		padding: 10px 14px;
		overflow: auto;
		border-radius: 4px;

		&:not(:first-of-type) {
			margin-top: 24px;
		}

		> div {
			flex: 1;
		}
	}

	&__column {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	&__footer {
		display: flex;

		&:not(:empty) {
			margin-top: 24px;
		}
	}

	&__row {
		display: flex;
		padding: 0 14px;
		margin-top: 24px;
		overflow-x: auto;
		gap: 24px;
	}

	&__spinner {
		margin: auto;
	}

	&__table-header {
		padding: 10px 14px;
		border-bottom: 1px solid var(--grey10);
		margin-bottom: 0;
	}

	&__table {
		min-height: 285px;
	}
}
