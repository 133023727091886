.avatar {
	height: 40px;
	width: 40px;
	min-width: 40px;
	border-radius: 50%;

	&_no-image {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--blue5);
		border: 2px solid var(--blue20);
		font-size: 25px;
		color: var(--black);
		text-transform: uppercase;
	}
}
