.settings-layout {
  display: flex;
  flex-direction: column;
  position: relative;

  &__form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__spinner-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &__build-info {
    opacity: 0.5;
    color: var(--grey100);
    white-space: break-spaces;
    font-size: 10px;
    line-height: 126%;
  }

  &__info-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }

  &__manual-link {
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin-top: 24px;
    align-items: center;

    a, a:visited {
      color: var(--blue60);
    }

    &:hover > a {
      color: var(--blue70);
    }
  }
}
