.rate-cell-renderer {
  display: flex;
  width: 100%;

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &_values {
      width: auto;
      align-items: flex-start;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label,
  &__value {
    letter-spacing: 0.033em;
    font-size: 12px;
    line-height: 126%;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
  }

  &__value {
    letter-spacing: unset;
  }

  &__label {
    color: var(--blue60);
    text-align: right;
    width: 105px;

    &::after {
      content: ':';
      margin-right: 2px;
    }
  }
}