.detail-cell {
	display: flex;
	flex-direction: column;
	$cell: &;

	&__header {
		color: var(--grey50);
		font-weight: 400;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: 0.018em;
		font-feature-settings: 'ss01' on;
		white-space: nowrap;
	}

	&__value,
	&__label {
		font-weight: 400;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: 0.018em;
		font-feature-settings: 'ss01' on;
		white-space: nowrap;
	}

	&__content {
		max-width: 250px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__value {
		display: flex;
		align-items: center;
		color: var(--grey100);
		margin-top: 4px;
		height: 28px;

		svg {
			margin-right: 4px;
		}

		&_link {
			cursor: pointer;
			color: var(--blue70);
			text-decoration: underline;
		}

		&_link {
			cursor: pointer;
			color: var(--blue70);
			text-decoration: underline;
		}

		&_bold {
			font-weight: 600;
		}
	}

	&__label {
		color: var(--grey50);
		margin-left: 4px;
	}
}
