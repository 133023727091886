.timepicker-container {
  display: flex;
  padding: 24px 0;
  justify-content: center;
}

.rc-picker {
  border: 0;
}

.rc-picker-input input {
  display: flex;
  height: 28px;
  padding: 6px 8px;
  border-radius: 6px;
  color: var(--grey100);
  font-family: var(--inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.252px;
  box-shadow: 0 0 0 1px var(--grey20);
  background: #FFFFFF;

  &:hover {
    background: var(--bg10);
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--blue40);
  }
}

.rc-picker-dropdown {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: var(--shadow8dp);
  z-index: 999;
}

.rc-picker-dropdown-hidden {
  display: none;
}

.rc-picker-footer {
  padding-top: 8px;
  border-top: 1px solid var(--grey5);
  background: #FFFFFF;
  margin-top: 4px;
}

.rc-picker-panel {
  width: 340px;
  background: #FFFFFF;
  border: 0;
}

.rc-picker-time-panel-cell-inner {
  color: var(--grey80) !important;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.rc-picker-time-panel-column {
  width: 58px;
  padding: 0 4px 0 4px;
  border-left: 1px solid var(--grey5);
  border-right: 1px solid var(--grey5);

  &:last-of-type {
    padding: 0 0 0 4px;
    width: 52px;
    border: 0;
  }

  &:first-of-type {
    padding: 0 4px 0 0;
    width: 52px;
    border: 0;
  }
}

.rc-picker-time-panel-cell-selected {
  background: var(--blue5) !important;

  &:hover {
    background: var(--blue5);
  }

  .rc-picker-time-panel-cell-inner {
    color: var(--grey100) !important;
  }
}

.rc-picker-time-panel-cell {
  width: 48px;
  max-width: 48px;
  padding: 5px 11px;
  border-radius: 4px;

  &:hover {
    background-color: var(--grey0);
  }
}

.rc-picker-now {
  padding: 4px 14px;
  height: 28px;

  a {
    color: var(--blue70);
    font-family: var(--inter);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 143%;
    letter-spacing: 0.252px;
  }
}

.rc-picker-ok button {
  padding: 4px 14px;
  border-radius: 6px;
  background: var(--blue60);
  //box-shadow: var(--shadow8dp);
  border: 0;
  height: 28px;
  cursor: pointer;

  color: var(--white);
  font-family: var(--inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 143%;
  letter-spacing: 0.252px;
}
