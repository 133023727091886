.modal {
  width: 90%;
  height: calc(100vh - 120px);
  padding: 32px 24px;
}

.title span {
  margin: 0 4px;
  color: var(--grey100);
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey10);
  border-radius: 4px;
  width: 100%;
}

.table {
  display: flex;
  flex: 1;

  > div {
    width: 100%;
  }
}

.tablePagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  padding: 4px 8px;
  border-top: 1px solid var(--grey5);
  font-size: 14px;

  > div {
    align-self: baseline;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  width: 420px;
  min-width: 420px;
  gap: 14px;
}

.settingsTitle {
  color: var(--grey40);
  margin-bottom: 10px;
}

.settingsButtonsWrapper {
  display: flex;
  justify-content: space-between;
}

.selectRowField input {
  box-shadow: 0 0 0 1px var(--blue60) !important;
}

.selectRowFieldWrapper {
  position: relative;
}

.selectRowFieldHintOverlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 1px solid var(--grey10);
  font-size: 12px;
  gap: 8px;
  max-width: 340px;
  padding: 14px;
  background-color: #fff;
  z-index: 2;
  border-radius: 4px;
  top: calc(100% + 10px);
  box-shadow: var(--shadow2dp);
  color: var(--grey50);

  span {
    font-size: 16px;
    color: var(--grey100);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 24px;
    transform: translateX(-50%);
    width: 0;
    border-style: solid;
  }

  &::before {
    border-width: 0 8px 8px;
    border-color: transparent transparent var(--grey10) transparent;
    z-index: 1;
  }

  &::after {
    border-width: 0 7px 7px;
    border-color: transparent transparent #fff transparent;
    z-index: 3;
    top: -7px;
  }
}

.hintsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
}

.hintRow {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: var(--grey60);
}

// костыль
.treeTable {
  max-width: calc(100vw - 211px);
  max-height: calc(100vh - 342px);
}

.errorsList {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 8px;
  max-height: calc(100vh - 342px);
}

.errorListItem {
  display: flex;
  height: 24px;
  gap: 6px;
  color: var(--grey50);

  span:first-of-type {
    color: var(--red60);
  }
  span:last-of-type {
    color: var(--grey100);
  }

  b {
    color: var(--grey100);
    font-weight: 500;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--grey5);
  }
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.tabsButtonsWrapper {
  display: flex;
  margin-top: auto;
  padding: 24px 2px 2px;
  justify-content: space-between;
  align-items: center;
}
