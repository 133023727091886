@import "../../../../assets/extend";

.container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}

.statsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--grey10);
}

.statsBlock {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.statBlockTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
}

.statsGrid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.statCol {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span:nth-child(1) {
    color: var(--grey100);

    font-size: 16px;
    font-weight: 500;
    line-height: 141%;
  }

  span:nth-child(2) {
    color: var(--grey50);
    font-size: 12px;
    line-height: 120%;
  }
}

.configContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.configRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.configLabel {
  display: flex;
  align-items: center;
  width: 170px;
  gap: 8px;

  span {
    color: var(--grey50);
  }
}

.chipsWrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;


  span[class*="bp3-text-overflow-ellipsis"] {
    flex-shrink: unset;
    white-space: unset;
  }

  span[class*="bp3-tag"] {
    height: unset;
  }
}

.callout {
  @extend %callout-overrider;
}

.scheduledDateWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
