.container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 571px);
  overflow-y: auto;
  overflow-x: hidden;
}

.hint {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: var(--grey40);

  > span {
    cursor: pointer;
  }
}

.header {
  display: flex;
  gap: 4px;
  background-color: var(--grey0);
  border-bottom: 1px solid var(--grey10);
  color: var(--grey40);
  margin-top: 14px;
  padding: 4px;
  font-size: 14px;

  > span {
    color: var(--grey100);
  }
}

.list {
  display: flex;
  flex-direction: column;
}

.listItem {
  display: grid;
  grid-template-columns: 16px 10px max-content 183px 16px 16px;
  font-size: 14px;
  align-items: center;
  grid-column-gap: 8px;
  cursor: pointer;
  padding: 6px 8px;

  > span[class*="bp3-icon"] {
    opacity: 0;
  }

  > div:first-of-type {
    width: 123px;
  }

  &:hover,
  &_active {
    > span[class*="bp3-icon"] {
      opacity: 1;
    }
  }
}

.addButton {
  color: var(--blue40);
  cursor: pointer;
  margin-left: 9px;
  margin-top: 4px;
  transition: color 0.2s;
  align-self: baseline;


  &_disabled {
    color: var(--blue5);
    cursor: not-allowed;
  }

  &:not(&_disabled):hover {
    color: var(--blue60);
  }
}

.iconButton {
  cursor: pointer;
  color: var(--grey30);
  transition: color 0.2s ease-in-out;

  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(&_disabled):hover {
    color: var(--color50);
  }
}

.confirmDialog {
  padding: 18px 24px;
  border-radius: 8px;

  div[class*="header"] {
    margin-bottom: 12px;
    font-size: 16px;
  }
  div[class*="footer"] {
    margin-top: 22px;
    padding: 2px;
  }
}
