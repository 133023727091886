@import "src/scss/variables";

.progress {
  display: flex;
  align-items: center;
  height: 24px;
  min-height: 24px;
  padding: 2px;
  margin-top: 2px;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  font-size: 12px;
  line-height: 25px;
  $progress: &;

  &__label {
    margin-left: 12px;
    z-index: 1;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__percent {
    margin-right: 12px;
    z-index: 1;
    font-family: $primary-font-bold;
  }

  &__bar {
    position: absolute;
    height: 20px;
    border-radius: 12px;
    transition: width 3s ease-in-out;
    animation: 0.5s switcher ease-in-out;
    transform-origin: left;
  }

    &_blue {
      background-color: rgba($accent-color, 0.2);

      #{$progress}__bar {
        background-color: $accent-color;
      }
    }

    &_green {
      background-color: rgba($color-green, 0.2);

      #{$progress}__bar {
        background-color: $color-green;
      }
    }

    &_red {
      background-color: rgba($color-attention, 0.2);

      #{$progress}__bar {
        background-color: $color-attention;
      }
    }

    &_grey {
      background-color: rgba($color-black, 0.2);

      #{$progress}__bar {
        background-color: $color-black;
      }
    }
  
}
