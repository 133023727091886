.default {
  width: fit-content;
  border-radius: 0.5rem;
}
.default div[class*="DayPicker-Day"],
.default div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  line-height: 2.5rem;
}

.default div[class*="DayPicker-Weekday"] {
  padding: unset;
  width: 2.5rem;
  height: 1.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
}

/*NavBar*/
.default div[class*="datepicker-caption"] {
  height: 2.5rem;
  margin: 0 2rem;
}
.default div[class*="datepicker-navbar"],
.default div[class*="datepicker-navbar"] > button {
  height: 2.5rem;
}
.default div[class*="datepicker-navbar"] > button svg {
  width: 1.25rem;
  height: 1.25rem;
}
.default div[class*="datepicker-month-select"] > select,
.default div[class*="datepicker-year-select"] > select {
  font-family: inherit;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  height: 2.5rem;
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
}
.default div[class*="datepicker-year-select"] > select {
  min-width: 68px;
}

.default div[class*="datepicker-month-select"] span > svg,
.default div[class*="datepicker-year-select"] span > svg {
  height: 2.5rem;
  width: 1.25rem;
}
.default div[class*="datepicker-month-select"] span,
.default div[class*="datepicker-year-select"] span {
  top: unset;
}

/*end*/
/*footer*/
.default div[class*="datepicker-footer"] > button {
  line-height: unset;
  height: 2.5rem;
  padding: 0 1rem;
}
/**/

/*shortcuts*/
.default ul[class*="daterangepicker-shortcuts"] {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
}
.default ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  height: 2.5rem;
  padding: 0;
  margin: 0.25rem;
  border-radius: 0.25rem;
}
.default ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] > div {
  margin: auto 1rem;
}
/**/
