.modal {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 608px;
  width: 100%;
  max-height: calc(100vh - 118px);
  position: relative;

  &_lg {
    max-width: 808px;
  }

  &_lx {
    min-width: 860px;
    max-width: 1150px;
    height: calc(100vh - 118px);

    > div {
      height: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-family: var(--inter);
    padding: 10px 24px;

    &__mode {
      color: var(--grey100);
      margin-right: 8px;
      font-weight: bold;
      font-size: 24px;
      line-height: 150%;
    }
  }
}

.mt16 {
  margin-top: 16px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mr16 {
  margin-right: 16px !important;
}

.bp3-dialog-container {
  padding-top: 56px;
  padding-left: 48px;
}

.bp3-toast-container{
  padding-left: 48px;
}

.asideOpened {
  .bp3-dialog-container {
    padding-left: 240px;
  }
  .bp3-toast-container{
    padding-left: 240px;
  }
}
