.array-field {

  &__input-icon {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 12px;
  }

  &__input {
    .bp3-input {
      font-size: 14px !important;
      line-height: 143% !important;
      letter-spacing: 0.018em !important;
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  &__item-input {
    .bp3-input {
      color: #2264D1 !important;
    }
  }
}
