/*CHECKBOX SHAPE CSS MODULE*/

/*CONTAINER*/
.container {
  width: fit-content;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
.container label[class*="control"] {
  display: flex;
  padding: 0;
  margin: 0;
}
.container.fill {
  width: 100%;
}
.box_layout {
  position: absolute;
  border-radius: 0.25rem;
}
.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*to RIGHT*/
.container label[class*="control"].right {
  flex-direction: row-reverse;
}
/*---------------------------------------*/

/*DEFAULT*/
.def {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
}
.def.with_helper {
  height: 2.75rem;
  line-height: 2.75rem;
}
.def .box_layout {
  width: 2.5rem;
  height: 2.5rem;
}
.def.with_helper .box_layout {
  top: 0.125rem;
}
.def .text {
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
.def[class*="control"] span[class*="control-indicator"],
.def[class*="control"] span[class*="control-indicator"]::before {
  padding: 0;
  margin: auto 0.6875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.125rem;
  z-index: 1;
}
.def.checked,
.dense.checked {
  font-weight: var(--font_weight_body_medium);
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
.def.checked input:checked ~ span[class*="control-indicator"]::before {
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e");
}
.def.checked input:indeterminate ~ span[class*="control-indicator"]::before,
.def input:indeterminate ~ span[class*="control-indicator"]::before {
  margin: auto;
}
/**-------------------------*/

/*DENSE*/
.dense {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  height: 1.75rem;
  line-height: 1.75rem;
  border-radius: 0.25rem;
}
.dense.with_helper {
  height: 2.5rem;
  line-height: 2.5rem;
}
.dense .box_layout {
  width: 1.75rem;
  height: 1.75rem;
}
.dense.with_helper .box_layout {
  top: 0.375rem;
}
.dense .text {
  margin-left: 0.375rem;
  margin-right: 0.5rem;
}
.dense[class*="control"] span[class*="control-indicator"],
.dense[class*="control"] span[class*="control-indicator"]:before {
  padding: 0;
  margin: auto 0.40625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 0.125rem;
  z-index: 1;
}
.dense.checked input:checked ~ span::before {
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e") !important;
}
.dense.checked input:indeterminate ~ span[class*="control-indicator"]::before,
.dense input:indeterminate ~ span[class*="control-indicator"]::before {
  margin: auto;
}
/**--------------------------*/

/*HELPER TEXT*/
.helperBox {
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
.helperBox > .helperText {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*--------------------------------------------*/

/*RTL*/
.rtl {
  text-align: right;
}
/*----------------------------------*/
