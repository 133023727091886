@import "src/assets/extend";

.payment-form {
  @extend %form;

  &__shadow {
    @extend %form__top-shadow;
  }

  &__row {
    @extend %form__row;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &_block-closer {
      @extend %form__row_block-closer;
    }
  }

  &__content {
    @extend %form__content;
  }

  &__exchange {
    @extend %form__block;

    &__title {
      @extend %form__block__title;
    }
  }
}
