.fields-block-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	&__subtitle {
		font-weight: 500;
		font-size: 20px;
		line-height: 158%;
		letter-spacing: 0.004em;
		color: var(--grey100);
		margin-right: 8px;

		&_required {
			&::before {
				content: '*';
				color: var(--red70);
			}
		}

		&_changed {
			color: var(--blue70);

			&::after {
				content: ' ●';
				color: var(--blue60);
			}
		}
	}

	&__row {
		display: flex;
		align-items: center;
		margin-bottom: 14px;
	}

	&__chips {
		color: var(--white) !important;
		border-radius: 4px !important;
		letter-spacing: 0.033em !important;
		font-feature-settings: 'ss01' on !important;
		font-weight: 400 !important;
		font-size: 12px !important;
		text-transform: capitalize !important;
	}
}
