@import "../../../../assets/extend";

.form {
  @extend %form;

  > div > div {
    gap: 24px;
  }

  div[class*="charge-price"] {
    margin-top: 0;
  }
}

.shadow {
  @extend %form__top-shadow;
}

.content {
  @extend %form__content;
}

.row {
  display: flex;
  gap: 24px;
}

.field {
  width: 100%;
}
