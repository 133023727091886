.sources-cell-renderer {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    border-radius: 4px;
    padding: 4px;
  }
}
