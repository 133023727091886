@import "src/assets/extend";

.users-form {
  @extend %form;

  &__shadow {
    @extend %form__top-shadow;
    top: 96px;
  }

  &__content,
  &__roles-list {
    @extend %form__content;
    padding-top: 0;
    max-height: calc(100vh - 400px);
  }

  &__roles-list {
    padding: 0;
  }

  &__row {
    @extend %form__row;
  }

  &__tabs {
    width: 100%;

    .bp3-tab-list {
      margin: 0 24px;
    }

    &__tab {
      display: flex;
      align-items: center;
      padding-bottom: 8px;

      &__spinner {
        margin-right: 8px !important;
      }

      &__icon {
        margin-right: 8px;

        svg, path {
          fill: var(--blue30) !important;
        }
      }

      &__label {
        color: var(--blue70);
      }
    }

    div[aria-selected="true"] {
      .bousers-form__tabs__tab__icon {
        svg, path{
          fill: var(--blue70) !important;
        }
      }

      .bousers-form__tabs__tab__label {
        color: var(--blue100);
      }
    }
  }

  &__category {
    display: flex;
    flex-direction: column;
    border-top: 2px solid var(--grey20);
  }

  &__category-title {
    color: var(--grey100);
    font-weight: 500;
    font-size: 20px;
    line-height: 158%;
    letter-spacing: 0.004em;
    padding: 13px 24px;
  }

  > div[class*="bp3-spinner"] {
    height: calc(100vh - 308px);
  }
}
