.datetime-cell-renderer {
  display: flex;
  flex: 1;

  &__time {
    color: var(--grey40);
  }

  &__timezone {
    font-size: 10px;
    font-style: normal;
    font-weight: normal;
    line-height: 126%;
    letter-spacing: 0.02em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
    color: var(--grey40);
  }
}
