/*FLAT STYLE*/
.flat {
  background: none;
  box-shadow: none;
  /*transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);*/
}

.flat[class*="button-group"] > button[class*="button"] {
  color: var(--color40);
  background-color: var(--white);
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
.flat > button[class*="button"]:hover {
  color: var(--color90);
  background-color: var(--color0);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.flat > button[class*="button"][class*="focused"] {
  background-color: var(--color5);
  color: var(--color90);
}

/*END STYLE*/
/*FILLED STYLE*/
.filled {
  background: none;
  box-shadow: none;
  background-color: var(--color10);
  border-radius: 0.25rem !important;
}

.filled[class*="button-group"] > button[class*="button"] {
  color: var(--color50);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
.filled > button[class*="button"]:hover {
  color: var(--color100);
  background-color: var(--color20);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.filled > button[class*="button"][class*="focused"] {
  background-color: var(--color50);
  color: var(--white);
}
/*END STYLE*/
/*SMOOTH STYLE*/
.smooth {
  background: none;
  box-shadow: none;
  background-color: var(--color5);
  border-radius: 0.25rem !important;
}
.smooth[class*="button-group"] > button[class*="button"] {
  color: var(--color40);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
.smooth > button[class*="button"]:hover {
  color: var(--color100);
  background-color: var(--color10);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.smooth > button[class*="button"][class*="focused"] {
  background-color: var(--color20);
  color: var(--color100);
}
/*END STYLE*/
/*OUTLINED STYLE*/
.outlined {
  background: none;
  background-color: var(--white);
  box-shadow: 0 0 0 1px var(--color20);
  border-radius: 0.25rem !important;
}
.outlined[class*="button-group"] > button[class*="button"] {
  color: var(--color50);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
  z-index: 1;
}
.outlined > button[class*="button"]:hover {
  color: var(--color90);
  background-color: var(--color0);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.outlined > button[class*="button"][class*="focused"] {
  background-color: var(--white);
  color: var(--color90);
  box-shadow: 0 0 0 2px var(--color30);
  z-index: 6;
  transition: box-shadow background-color color 0.25s
    cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
/*END STYLE*/

/*RAISED STYLE*/
.raised {
  background: none;
  box-shadow: none;
  background-color: var(--color0);
  border-radius: 0.25rem !important;
}
.raised[class*="button-group"] > button[class*="button"] {
  color: var(--color40);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
  z-index: 1;
}
.raised > button[class*="button"]:hover {
  color: var(--color90);
  background-color: var(--color5);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.raised > button[class*="button"][class*="focused"] {
  background-color: var(--white);
  color: var(--color90);
  box-shadow: var(--shadow2dp);
  z-index: 6;
  transition: box-shadow background-color color 0.25s
    cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
/*END STYLE*/
