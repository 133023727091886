@import "src/scss/variables";

.buttons-cell-renderer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 12px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }

    @media (max-width: $screen-M) {
      height: 18px;
    }
  }
}
