.container {
  display: flex;
  flex-direction: column;
  gap: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.option {
  display: flex;
  flex-direction: row;
  gap: 3px;
}
.label {
  color: var(--grey50);
}
