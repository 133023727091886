.info {
  font-size: 16px;
  font-weight: normal;
  line-height: 136%;

  > b {
    font-weight: bold;
  }

  > i {
    font-style: italic;
  }

  > span {
    line-height: 246%;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;
}

.radio {
  display: flex;
  gap: 8px;
  line-height: 146%;

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 3px 0 0 0;
    cursor: pointer;
    font: inherit;
    color: var(--grey100);
    width: 1.15em;
    min-width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--grey100);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    cursor: pointer;
    width: 0.45em;
    height: 0.45em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--grey100);
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  &_red {
    input[type="radio"] {
      border: 0.15em solid var(--red70);
    }

    input[type="radio"]::before {
      box-shadow: inset 1em 1em var(--red70);
    }
  }
}

.radioLabel {
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;

  > b {
    font-weight: bold;
    margin: 0 4px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid var(--grey10);
  overflow: hidden;
  margin-top: 16px;
}

.searchRow {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-bottom: 1px solid var(--grey10);
  gap: 8px;

  > input {
    border: none;
    font-size: 16px;
    width: 100%;
    padding: 0;
  }

  > svg {
    width: 16px;
    height: 16px;
    fill: var(--grey50);
  }
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 12px;
  padding: 16px 8px;
}

.listItem {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  opacity: 0.7;
  transition: opacity 0.2s;
  gap: 8px;

  &:hover {
    opacity: 0.9;
  }

  &:has(input:checked) {
    opacity: 1;
  }
}

.label {
  user-select: none;
  cursor: pointer;
  transition: color 0.2s;
}

.addButton {
  cursor: pointer;
  fill: var(--grey20);
  transition: fill 0.2s ease-in-out;
  height: 15px;
  width: 15px;
  min-width: 15px;

  &_disabled {
    cursor: not-allowed;
  }

  &:not(&_disabled):hover {
    fill: var(--blue50);
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 4px;
  transition: border-bottom-color 0.2s;
  border-bottom: 1px solid transparent;

  &:has(input:not(:read-only)) {
    border-bottom: 1px solid var(--blue20);

    > svg {
      display: block;
    }

    &:has(input:focus) {
      border-bottom: 1px solid var(--blue40);
    }

    &:hover {
      border-bottom: 1px solid var(--blue30);
    }
  }

  &:hover > svg {
    display: block;
  }

  > input {
    width: 100%;
    border: none;
    font-size: 16px;
    padding: 0 2px;
  }

  > svg {
    display: none;
    fill: var(--grey20);
    cursor: pointer;
    transition: fill 0.2s;
    width: 16px;

    &:hover {
      fill: var(--grey50);
    }
  }
}
