.table {
  display: grid;
  grid-auto-rows: min-content;
  width: 100%;
  overflow: auto;
  border: 1px solid var(--grey5);
  padding-bottom: 20%;
  margin-top: 24px;

  &_pinLastColumn {
    .header > div:last-child {
      right: 0;
      z-index: 3;
    }

    .row > div:last-of-type {
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }
}

.header {
  display: contents;
}

.header > div {
  grid-column: auto;
  padding: 0 8px;
  white-space: nowrap;
  background-color: #f2f2f2;
  color: var(--grey50);
  position: sticky;
  top: 0;
  z-index: 2;
  overflow: hidden;
  font-size: 14px;
  display: flex;
  align-items: center;

  > span {
    cursor: pointer;
    color: var(--grey40);
    transition: color 0.2s;
    margin-left: 4px;

    &:hover {
      color: var(--grey70);
    }
  }
}

.row {
  display: contents;

  > div {
    grid-column: auto;
    padding: 6px;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
    min-height: 42px;
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_active,
  &:hover {
    .controlsWrapper {
      opacity: 1;
    }
  }
}

.currency {
  margin-left: 4px;
  color: var(--grey40);
}

.controlsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  opacity: 0;
  transition: opacity 0.2s;

  > span {
    color: var(--color50);
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
      color: var(--color70);
    }
  }
}

.addButton {
  padding: 8px;
  color: var(--grey50);
  transition: color 0.2s;
  cursor: pointer;
  min-height: 42px;

  &_disabled {
    cursor: not-allowed;
  }

  &:not(&_disabled):hover {
    color: var(--blue50);
  }
}

.click {
  display: contents;
  &:hover {
    color: #4289ff;
    cursor: pointer
  }
}

