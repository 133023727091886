.container {
  display: flex;
  flex-direction: column;
  padding: 14px 7px;
  background-color: var(--white);
  height: 100%;
  cursor: initial;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
  color: var(--grey70);
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss01' on;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0275em;
  gap: 8px;

  > button {
    margin-left: auto;
  }
}

.grid {
  display: grid;
  width: 100%;
  margin-top: 12px;
  grid-template-columns: repeat(6, minmax(min-content, 1fr));
  grid-template-rows: auto;
  overflow: auto;
}

.header {
  display: contents;

  > div {
    background-color: var(--grey5);
    padding: 4px 6px;
    position: sticky;
    top: 0;
    font-size: 14px;
  }
}

.row {
  display: contents;

  > div {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    font-size: 14px;
  }
}

.valueCell {
  display: flex;
  align-items: center;
  gap: 4px;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: text;
    max-width: 300px;
    font-size: 14px;
  }
}
