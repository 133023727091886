@import "../../../../assets/extend";

.http-connections-form {
	@extend %form;

	&__shadow {
		@extend %form__top-shadow;
	}

	&__content {
		@extend %form__content;
		padding: 24px 24px 12px;
	}
}
